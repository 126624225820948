import React from "react";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const Blogs = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let apiBaseUrl = window.location.hostname;
    if (apiBaseUrl === "localhost") {
      //apiBaseUrl = "http://localhost:3105";
      apiBaseUrl = "http://api.websitemasters.net";
    } else {
      apiBaseUrl = "http://api.websitemasters.net";
    }
    const url = apiBaseUrl + "/api/blogs/get_all_blogs";
    const settings = {
      method: "POST",
    };
    async function fetchData() {
      await fetch(url, settings)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          console.log(res);
          var blog_data = res.data;
          setData(blog_data);
        });
    }
    fetchData();
  }, []);
  return (
    <>
      <div className="row-box">
        <Row>
          {data.map((data_each) => (
            <Col md={6}>
              <div className="blog-boxes">
                <div class="blog_image">
                  <img
                    src={
                      "http://api.websitemasters.net" +
                      data_each.image_url
                    }
                    // src={'http://api.websitemasters.net/api'+data_each.image_url}
                    alt={data_each.blog_title}
                    
                  />
                </div>
                <div className="blog-content">
                  <h4>{data_each.blog_date}</h4>
                  <h3> {data_each.blog_title}</h3>
                  <p>{data_each.blog_description}</p>
                </div>
                <div className="blog-button">
                  <div className="list-btn btn_2 btn_cut">
                    <Link to={"/blog-details/" + data_each._id}>
                      <svg>
                        <rect
                          x="0"
                          y="0"
                          fill="none"
                          width="100%"
                          height="100%"
                        />
                      </svg>
                      Read full Article
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Blogs;
