import React from "react";
import { Container } from "react-bootstrap";
import buontuin from "../assets/images/projects/buontuin_bg.webp";
import wordpress from "../assets/images/projects/icons/wordpress.svg";
import php from "../assets/images/projects/icons/php.svg";
import mysql from "../assets/images/projects/icons/mysql.svg";
import jquery from "../assets/images/projects/icons/jquery.svg";
import { Link } from "react-router-dom";
const Buontuin = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Case Study</h1>
          </div>
        </Container>
      </section>
      <section className="section single_section">
        <Container>
          <div className="single_padd left-padd">
            <div className="single_text">
              <h2>Buontuin</h2>
              <p>
                Buontuin is a cloth brand that has emerged out of love for
                style, call for quality, and value for money.
              </p>
              <p>
                The company offers luxurious and affordable sustainable fashion
                designs mainly for women.
              </p>
              <div className="single_box">
                <h3>Objective</h3>
                <p>
                  To create a web application that enables her customers to
                  place orders online as running sales through a messaging
                  platform was tedious.{" "}
                </p>
              </div>
              <div className="single_box">
                <h3>Our Solution</h3>
                <p>
                  Website Masters developed an online platform that showcases
                  the list of products along with its description, body
                  measurements, body types, along with Fit info to ensure
                  customers{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Online_shopping"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    buying online
                  </a>{" "}
                  could almost get the same experience as in the case of
                  trialing clothes in reality.
                </p>
              </div>
              <div className="single_image">
                <img
                  src={buontuin}
                  alt="single project"
                  width={978}
                  height={666}
                  className="img-fluid"
                />
              </div>
              <div className="single_box">
                <h3>Features</h3>
                <ul>
                  <li>
                    <span>Informative Website </span>
                  </li>
                  <li>
                    <span>Social media integration</span>
                  </li>
                  <li>
                    <span>Exchange and Returns</span>
                  </li>
                  <li>
                    <span>Shipping </span>
                  </li>
                </ul>
              </div>
              <div className="single_box">
                <h3>Team Work</h3>
                <p>
                  We employ different methodologies according to the demands and
                  requirements of the project. Whenever our customers and
                  partners recommend using{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Agile_software_development"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Agile
                  </a>{" "}
                  methodologies (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.arnia.com/how-we-do-scrum-in-arnia/"
                  >
                    Scrum
                  </a>{" "}
                  ,{" "}
                  <a
                    href="https://www.arnia.com/how-we-do-kanban-in-arnia/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kanban
                  </a>
                  ) from the inception of the project, we promptly apply the
                  same.
                </p>
                <p>
                  In cases where the choice is left to our engineers, we
                  carefully analyze the project specifics, and define a project
                  management methodology based on Scrum or Kanban which best
                  fits the specific project needs and context.
                </p>
                <p>
                  In this case, the choice was left to us and therefore we
                  deployed a team of one designer and one developer who
                  interacted closely with the Client Service Team. As in all of
                  our projects, we worked in an agile and flexible way. We had
                  daily scrum meetings with sprints and completed the project in
                  5 days.
                </p>
                <p>
                  <b>Note:</b> Since the old website was still functional, we
                  migrated and deployed the new one within a downtime of 2
                  minutes in the night when the traffic to the website was
                  absolutely low.
                </p>
              </div>
              <div className="plateform_box">
                <div className="plateform_text">
                  <h3>Technologies</h3>
                  <h4>Website</h4>
                  <ul>
                    <li>
                      <a
                        href="https://wordpress.org/about/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src={wordpress}
                            alt="wordpress"
                            width={41}
                            height={41}
                          />
                        </span>
                        Wordpress
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.php.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={php} alt="php" width={50} height={50} />
                        </span>
                        PHP
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jquery.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src={jquery}
                            alt="jquery"
                            width={50}
                            height={50}
                          />
                        </span>
                        jQuery
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.mysql.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={mysql} alt="mysql" width={50} height={50} />
                        </span>
                        MySql
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="free-quote blue-quote">
              <h2 className="h2-title">
                Get a free <span>quote!</span>
              </h2>
              <div className="list-btn btn_2 btn_cut">
                <Link to="/contact">
                  <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                  </svg>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Buontuin;
