import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import slider1 from "../assets/images/projects/slider/prilyn1.webp";
import slider2 from "../assets/images/projects/slider/prilyn2.webp";
import slider3 from "../assets/images/projects/slider/prilyn3.webp";
import slider4 from "../assets/images/projects/slider/prilyn4.webp";

const PrilynSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="project_carousel">
        <Slider {...settings}>
          <div className="projects">
            <img src={slider1} alt="prilyn" />
          </div>
          <div className="projects">
            <img src={slider2} alt="prilyn" />
          </div>
          <div className="projects">
            <img src={slider3} alt="prilyn" />
          </div>
          <div className="projects">
            <img src={slider4} alt="prilyn" />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default PrilynSlider;
