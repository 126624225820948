import React from "react";
import { Container, Col } from "react-bootstrap";
import Helmet from "react-helmet";
import Aform from "../Helper/Affliateform";
const Affliateform = () => {
  return (
    <>
      <Helmet>
        <title>Website Masters – Get in touch us</title>
        <meta
          name="description"
          content="We design, development and marketing top-ranked websites. Contact us today to arrange a meeting and see yourself how far we can take your website."
        />
      </Helmet>
      <section className="section top-banner">
        <Container>
          <div className="banner-box">
            <h2>Become Website Masters Affiliate</h2>
          </div>
        </Container>
      </section>
      <section className="form-heading">
        
      </section>
      <section className="section affliateform-page">
        <Container>
        <div className="left-padd pt-5">
          <h2 className="form-title text-black">
            Signup as a new affiliate
          </h2>
          <p>
            Signup as a new affiliate to earn 20% commission on
            every successful referral sale. We will review your application and
            approve the affiliates manually, so please enter all the information
            correctly.
          </p>
        </div>
          <div className="left-padd">
            <div className="affliate_form">
                <Col lg={12}>
                  <Aform />
                </Col>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Affliateform;
