import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Singleblog from "../Helper/Singleblog";
const BlogDetails = () => {
  return(
    <>
        <section className="section top-banner">
            <Container>
            <div className="banner-box left-padd">
                <h1 className="mb-3">Our Blogs</h1>
            </div>
            </Container>
        </section>
        <section className="section blogs-section">
            <div className="blog-white"></div>
            <Container>
            <div className="blog-box left-padd">
                    <div className="row-box">
                        <Singleblog/>
                        
                    </div>
                </div>
            </Container>
        </section>
    </>
  );
};

export default BlogDetails;