import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../assets/images/logo.webp";
import icon from "../assets/images/icons/check_icon.svg";
import blackLogo from "../assets/images/footer_logo.webp";
import { NavLink, useLocation } from "react-router-dom";
import $ from "jquery";
$(document).on("click", ".navbar-toggler", function () {
  $("#navbarScroll").toggleClass("show");
});
$(document).on("click", "#navbarScroll a", function () {
  $("#navbarScroll").removeClass("show");
});
$(document).on("click", ".nav_times", function () {
  $("#navbarScroll").removeClass("show");
});
const Header = () => {
  const { pathname } = useLocation();
  console.log('pathname',pathname);
  var isHome = "";
  if (pathname === "/") {
    isHome = "active";
  } else {
    isHome = "inactive";
  }
  console.log('isHome',isHome);
  return (
    <header className="header-main">
      <div className="header_tobar">
        <Container>
          <div className="d-flex justify-content-end">
            <div className="top_header-text">
              Rated{" "}
              <span className="top_rate">
                <img
                  src="./yellow_star.svg"
                  alt="star"
                  width={11}
                  height={11}
                />
                <img
                  src="./yellow_star.svg"
                  alt="star"
                  width={11}
                  height={11}
                />
                <img
                  src="./yellow_star.svg"
                  alt="star"
                  width={11}
                  height={11}
                />
                <img
                  src="./yellow_star.svg"
                  alt="star"
                  width={11}
                  height={11}
                />
                <img
                  src="./yellow_star.svg"
                  alt="star"
                  width={11}
                  height={11}
                />
              </span>
              <strong>
                <sup>5</sup> stars &nbsp;
              </strong>
              on
              <span className="top_google">
                <a
                  href="https://g.page/euodias?share"
                  target="_blank"
                  aria-label="freelancer"
                  rel="noopener noreferrer"
                >
                  <img src="./google.webp" alt="star" width={57} height={20} />
                </a>
                {/* <b>and</b>
                <a
                  href="https://www.freelancer.com/u/goldcodeinfotech"
                  target="_blank"
                  aria-label="freelancer"
                  rel="noopener noreferrer"
                >
                  <img
                    src="./freelancer.webp"
                    alt="star"
                    width={80}
                    height={20}
                  />
                </a> */}
              </span>
            </div>
          </div>
        </Container>
      </div>
      <Navbar bg="light" variant="light" className="trans-nav" expand="lg">
        <Container className="">
          <NavLink to="/" className="navbar-brand">
            <img
              src="./logo1.svg"
              alt="logo"
              className="white-logo"
              width={170}
              height={37}
            />
            <img
              src="./logo2.svg"
              alt="logo"
              className="black-logo"
              width={170}
              height={37}
            />
          </NavLink>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <div className="navbar-collapse collapse" id="navbarScroll">
            <div className="nav_times">&times;</div>
            <ul className="me-auto navbar-nav">
              <li>
                <NavLink className={isHome} to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="active" to="/about">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="active" to="/services">
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="active" to="/pricing">
                  Pricing
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="active" to="/case-studies">
                  Case Studies
                </NavLink>
              </li>
              {/* <div className=""> */}
              <li className="ms-auto last_list selected_item">
                <NavLink className="bold-link" to="/b2b-partnership">
                  B2B Partnership
                </NavLink>
                <NavLink className="get_touch active" to="/contact">
                  Get in Touch
                </NavLink>
              </li>
              {/* </div> */}
            </ul>
          </div>
          <div className="checkout-header_secure">
            Secure Checkout <img src={icon} alt="icon" width={25} height={29} />
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
