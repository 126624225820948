import React from "react";
import { useEffect } from "react";
import { Container, Row, Col, Tab, Tabs, Pagination } from "react-bootstrap";
import arun from "../assets/images/masters/arun.webp";
import vikram from "../assets/images/masters/vikram.webp";
import demo from "../assets/images/masters/demo_image.webp";
import alex from "../assets/images/masters/alex.webp";
import jasbir from "../assets/images/masters/jasbir.webp";
import melvin from "../assets/images/masters/melvin.webp";
import sazid from "../assets/images/masters/sazid.webp";
import subrata from "../assets/images/masters/subrata.webp";
import seshu from "../assets/images/masters/seshu.webp";
// import joseph from "../assets/images/masters/joseph.webp";
import ahmed from "../assets/images/masters/ahmad.webp";
import moses from "../assets/images/masters/moses.webp";
import gurvinder from "../assets/images/masters/gurvinder.webp";
import sumanth from "../assets/images/masters/sumanth.webp";
import vishal from "../assets/images/masters/vishal.webp";
import shahrukh from "../assets/images/masters/shahrukh.webp";
import manpreet from "../assets/images/masters/manpreet_kaur.webp";
// projects
import project1 from "../assets/images/projects/proj1.webp";
import project2 from "../assets/images/projects/proj2.webp";
import project3 from "../assets/images/projects/proj3.webp";
import project4 from "../assets/images/projects/proj4.webp";
import project5 from "../assets/images/projects/proj5.webp";
import project6 from "../assets/images/projects/proj6.webp";
import project7 from "../assets/images/projects/proj7.webp";
import project8 from "../assets/images/projects/proj8.webp";
import project9 from "../assets/images/projects/prylin_proj.webp";
import abhyantaadya from "../assets/images/projects/logos/abhyantaadya.webp";
import pexeek from "../assets/images/projects/logos/pexeek.webp";
import skopeOne from "../assets/images/projects/logos/skopeOne.webp";
import prilyn from "../assets/images/projects/logos/prilyn.webp";
import Comsort from "../assets/images/projects/logos/ComsortLogo.webp";
import PrilynSlider from "../Helper/PrilynSlider";
import HumanSlider from "../Helper/HumanSlider";
import Helmet from "react-helmet";
import SowSlider from "../Helper/SowSlider";
const About = () => {
  useEffect(() => {
    document.body.classList.add("about-page");
    return () => {
      document.body.classList.remove("about-page");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Website Masters - Web Design, Development & Marketing Agency
        </title>
        <meta
          name="description"
          content="Website Masters is a web development company in India. You deserve a custom build website.We have expertise in building best custom website development, ecommerce development, plug- in & module development solutions and services."
        />
      </Helmet>
      <section className="section about_page">
        <div className="about_banner">
          <Container className="container-1240">
            <h2 className="h2-title text-white">
              About <span>Us</span>
            </h2>
            <p>
              We have been designing, developing, branding, optimizing, and
              creating apps and software for a number of clients, belonging to
              various industry dimensions. Implementing the latest design ideas
              and innovative technologies, followed by tried-and-proven
              techniques, has helped us to devise cutting-edge solutions for our
              clients.
            </p>
          </Container>
        </div>
        <div className="about-content">
          <Container>
            <div className="about-bg">
              <div className="our_portfolio">
                <span className="blue_port">
                  All Bespoke designs. Most advanced Technologies
                </span>
                <h2 className="h2-title text-black">
                  We let our Portfolio{" "}
                  <span className="d-block">do the talk.</span>
                </h2>
                <div className="tabs_box">
                  <Tabs
                    defaultActiveKey="website_cms"
                    id="tab_control"
                    className="mb-3"
                  >
                    <Tab eventKey="website_cms" title="Website, CMS">
                      <Row className="tab_row">
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={project1}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, CMS</h6>
                            <h5>EcoStores</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={project2}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, CMS</h6>
                            <h5>Hi Live Interiors</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={project3}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, CMS</h6>
                            <h5>Hilforte</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={project4}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, CMS</h6>
                            <h5>Red Rope</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={project5}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, CMS</h6>
                            <h5>Buontuin</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={project6}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, CMS</h6>
                            <h5>ASC School</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={project7}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, CMS</h6>
                            <h5>Vortex Solution</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={project8}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, CMS</h6>
                            <h5>Sam Consulting</h5>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="e_commerce" title="E-Commerce">
                      <Row className="tab_row">
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <PrilynSlider />
                            </div>
                            <h6>Mobile, App</h6>
                            <h5>Prilyn </h5>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="mobile_apps" title="Web & Mobile Apps">
                      <Row className="tab_row">
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <PrilynSlider />
                            </div>
                            <h6>Mobile App</h6>
                            <h5>Prilyn</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <HumanSlider />
                            </div>
                            <h6>Mobile App</h6>
                            <h5>The human power</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <SowSlider />
                            </div>
                            <h6>Mobile App</h6>
                            <h5>SowWork</h5>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="logo_branding" title="Logo & Branding">
                      <Row className="tab_row">
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={skopeOne}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, Branding</h6>
                            <h5>Skope One</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={pexeek}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, Branding</h6>
                            <h5>Pexeek</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={abhyantaadya}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, Branding</h6>
                            <h5>Abhyantaadya</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={prilyn}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, Branding</h6>
                            <h5>Prilyn</h5>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="port_box">
                            <div className="img_box">
                              <img
                                src={Comsort}
                                alt="project"
                                width={438}
                                height={507}
                              />
                            </div>
                            <h6>Website, Branding</h6>
                            <h5>Comsort</h5>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </Container>
          <div className="meet_masters">
            <Container>
              <div className="left-padd">
                <h2 className="h2-title text-black">
                  Meet few of our
                  <span className="d-block">MASTERS</span>
                </h2>
                <Row className="master_row">
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={arun}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Arun Singh</h3>
                        <h4>
                          HTML, CSS, Js <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>

                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={subrata}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Subrata Sutradhar</h3>
                        <h4>
                          React Native <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={vikram}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Vikram Kumar</h3>
                        <h4>
                          Graphics <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={sazid}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Sazid Ahmed</h3>
                        <h4>
                          Node <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={jasbir}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Jasbir Kaur</h3>
                        <h4>Hr</h4>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={tuan}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Tuan Zhang</h3>
                        <h4>
                          Magento <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col> */}
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={seshu}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Seshuvardhan Reddy</h3>
                        <h4>
                          PHP <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={melvin}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Melvin Shadrach</h3>
                        <h4>
                          Project <span>Coordinator</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={ahmed}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Ahmed Raza</h3>
                        <h4>
                          Devops <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>

                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={alex}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Alex Godwin</h3>
                        <h4>
                          UI/UX <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={moses}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Moses Thumaty</h3>
                        <h4>
                          HR <span>Manager</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={gurvinder}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Gurvinder Singh</h3>
                        <h4>
                          Angular <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={shahrukh}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Shahrukh Khan</h3>
                        <h4>
                          React Native <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>

                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={demo}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Raj Kumar</h3>
                        <h4>
                          React <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={manpreet}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Manpreet Kaur</h3>
                        <h4>
                          SEO <span>Manager</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={sumanth}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Sumanth Pasupulati</h3>
                        <h4>
                          SEO <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className="masters">
                      <div className="masters_img">
                        <img
                          src={vishal}
                          alt="masters"
                          width={218}
                          height={219}
                        />
                      </div>
                      <div className="master_text">
                        <h3>Vishal Gupta</h3>
                        <h4>
                          SEO <span>Master</span>
                        </h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container>
            <div className="common-blue-box">
              <div className=" text-end">
                <h2 className="h2-title text-start d-inline-block">
                  Whatever may be <br />
                  your <span>requirement.</span>
                </h2>
              </div>
              <div className="common-text">
                <p>
                  Be it a simple website design, a complex data driven web
                  application development, an ecommerce website, a native or
                  cross platform mobile app development, a logo and brand
                  identity design, a video production or a full fledged digital
                  marketing campaign - we have a solution for you.
                </p>
              </div>
              <div className="free-quote">
                <h2 className="h2-title text-black">
                  Get a free <span>quote!</span>
                </h2>
                <div className="list-btn patch-white btn_2">
                  <a href="/contact">
                    <svg>
                      <rect
                        x="0"
                        y="0"
                        fill="none"
                        width="100%"
                        height="100%"
                      />
                    </svg>
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default About;
