import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import logo1 from "../assets/images/logos/logo1.webp";
import logo2 from "../assets/images/logos/logo2.webp";
import logo3 from "../assets/images/logos/logo3.webp";
import logo4 from "../assets/images/logos/logo4.webp";
import logo5 from "../assets/images/logos/logo5.webp";
import logo6 from "../assets/images/logos/logo6.webp";
import logo7 from "../assets/images/logos/logo7.webp";
import comsort from "../assets/images/logos/comsort.webp";
const Logo = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="client_carousel">
        <Slider {...settings}>
          <div className="box-img">
            <div>
              <img src={logo1} alt="logo" width={290} height={140} />
            </div>
          </div>
          <div className="box-img">
            <div>
              <img src={logo2} alt="logo" width={290} height={140} />
            </div>
          </div>
          <div className="box-img">
            <div>
              <img src={logo3} alt="logo" width={290} height={140} />
            </div>
          </div>
          <div className="box-img">
            <div style={{ background: '#DFFCFF'}}> 
              <img src={comsort} alt="logo" width={290} height={140}/>
            </div>
          </div>
          <div className="box-img">
            <div>
              <img src={logo4} alt="logo" width={290} height={140} />
            </div>
          </div>
          <div className="box-img">
            <div>
              <img src={logo5} alt="logo" width={290} height={140} />
            </div>
          </div>
          <div className="box-img">
            <div>
              <img src={logo6} alt="logo" width={290} height={140} />
            </div>
          </div>
          <div className="box-img">
            <div>
              <img src={logo7} alt="logo" width={290} height={140} />
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Logo;
