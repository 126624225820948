import React from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const HomeSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: 'ondemand',
  };
  return (
    <>
      <div className="project_carousel">
        <Slider {...settings}>
          <div className="projects">
            <a
              href="https://play.google.com/store/apps/details?id=com.actionplus"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="slider/action_plus.webp" alt="Slider" width="460" height="584" />
              <h6>OUR RECENT PROJECTS</h6>
            </a>
          </div>
          <div className="projects">
            <a
              href="https://www.comsort.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="slider/comsort.webp" alt="Slider" width="460" height="584"/>
              <h6>OUR RECENT PROJECTS</h6>
            </a>
          </div>
          <div className="projects">
            <Link to="/case/prylin">
              <img src="slider/prilyn.webp" alt="Slider" width="460" height="584"/>
              <h6>OUR RECENT PROJECTS</h6>
            </Link>
          </div>
          <div className="projects">
            <a
              href="https://www.redrope.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="slider/red_rope.webp" alt="Slider" width="460" height="584"/>
              <h6>OUR RECENT PROJECTS</h6>
            </a>
          </div>

          <div className="projects">
            <a
              href="https://kudaly.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="slider/kudaly.webp" alt="Slider" width="460" height="584"/>
              <h6>OUR RECENT PROJECTS</h6>
            </a>
          </div>
          <div className="projects">
            <img src="slider/sow_work.webp" alt="Slider" width="460" height="584"/>
            <h6>OUR RECENT PROJECTS</h6>
          </div>
          <div className="projects">
            <img src="slider/the_human.webp" alt="Slider" width="460" height="584"/>
            <h6>OUR RECENT PROJECTS</h6>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default HomeSlider;
