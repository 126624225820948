import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContactForm from "../Helper/ContactForm";
import Helmet from "react-helmet";
const Pricing = () => {
  return (
    <>
      <Helmet>
        <title>Website Masters –Pricing page for services</title>
        <meta
          name="description"
          content="Choose the plan that’s right for you. Get best services from Website Master."
        />
      </Helmet>
      <section className="section top-banner">
        <Container>
          <div className="banner-box">
            <h1 className="mb-3">Pricing</h1>
          </div>
        </Container>
      </section>
      <section className="develop_section">
        <Container id="title1" className="top_cont">
          <div className="left-padd pt-5">
            <h2 className="h2-title text-black">
              Website <span>Development</span>
            </h2>
          </div>
          <div className="development_box">
            <Row className="service_row justify-content-center">
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="star-icon"></i>Web Development
                  </h3>
                  <p>
                    Present your activity online with <br />
                    simplicity.
                  </p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="reg_price">
                        <small>$</small>999<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="pro-icon"></i>Pro Package
                  </h3>
                  <p>
                    Boost your online presence with a Website and Attractive
                    Design.
                  </p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="reg_price">
                        <small>$</small>1,699<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="advance-icon"></i>Advanced Package
                  </h3>
                  <p>
                    Your online shop with secure <br />
                    transactions.
                  </p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="reg_price">
                        <small>$</small>2,499<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mobile_cont">
          <div className="development_box">
            <Row className="service_row justify-content-center">
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    Website <span>Development</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="star-icon"></i>Web Development
                      </h3>
                      <p>
                        Present your activity online with <br />
                        simplicity.
                      </p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="reg_price">
                            <small>$</small>999<b>/mo</b>
                          </span>
                        </h4>
                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>8 to 10 page website on Wordpress.</li>
                    <li>Time: 2 weeks</li>
                    <li>3 options to select theme.</li>
                    <li>Google Map Integration.</li>
                    <li>Blogs and News Included.</li>
                    <li>Admin to manage content and images.</li>
                    <li>SEO friendly Website.</li>
                    <li>Fully mobile responsive website.</li>
                    <li>LOGO Design Included.</li>
                    <li>Contact US, Services and About us Page.</li>
                    <li>Social Media integration included.</li>
                    <li>Training in the use of the back-office.</li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    Website <span>Development</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="pro-icon"></i>Pro Package
                      </h3>
                      <p>
                        Boost your online presence with a Website and Attractive
                        Design.
                      </p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="reg_price">
                            <small>$</small>1,699<b>/mo</b>
                          </span>
                        </h4>
                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>10 to 20 page website on Wordpress.</li>
                    <li>Time: 3 weeks</li>
                    <li>Unlimited options to select design.</li>
                    <li>Google Map Integration.</li>
                    <li>Custom Blogs and News Included.</li>
                    <li>Admin to manage content and images.</li>
                    <li>ON Page SEO Free.</li>
                    <li>Fully Mobile Responsive Website.</li>
                    <li>LOGO Design Included.</li>
                    <li>Maintenance and Support for 1 year.</li>
                    <li>Social Media integration included.</li>
                    <li>Training in the use of the back-office.</li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    Website <span>Development</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="advance-icon"></i>Advanced Package
                      </h3>
                      <p>
                        Your online shop with secure <br />
                        transactions.
                      </p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="reg_price">
                            <small>$</small>2,499<b>/mo</b>
                          </span>
                        </h4>
                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>Unlimited pages on Custom PHP.</li>
                    <li>Time: Depends on requirement.</li>
                    <li>Unlimited options to select design.</li>
                    <li>Google Map Integration.</li>
                    <li>Custom Blogs and News Included.</li>
                    <li>Custom Admin to manage website.</li>
                    <li>ON Page SEO Free.</li>
                    <li>Fully Mobile Responsive Website.</li>
                    <li>LOGO Design Included.</li>
                    <li>Maintenance and Support for 3 year.</li>
                    <li>Social Media integration included.</li>
                    <li>Training in the use of the back-office.</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container id="title2" className="top_cont">
          <div className="left-padd pt-5">
            <h2 className="h2-title text-black">
              Ecommerce <span>Development</span>
            </h2>
          </div>
          <div className="development_box">
            <Row className="service_row justify-content-center">
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="star-icon"></i>Startup Store
                  </h3>
                  <p>Best For Start-ups</p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="reg_price">
                        <small>$</small>1,299<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                      {/* <h5>Package includes:</h5> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="pro-icon"></i>MidSize Store
                  </h3>
                  <p>Best For Setup Business</p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="reg_price">
                        <small>$</small>2,499<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                      {/* <h5>Package includes:</h5> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="advance-icon"></i>Large Store
                  </h3>
                  <p>Best For Big Business</p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="reg_price">
                        <small>$</small>2,899<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                      {/* <h5>Package includes:</h5> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mobile_cont">
          <div className="development_box">
            <Row className="service_row justify-content-center">
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    Ecommerce <span>Development</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="star-icon"></i>Startup Store
                      </h3>
                      <p>Best For Start-ups</p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="reg_price">
                            <small>$</small>1,299<b>/mo</b>
                          </span>
                        </h4>
                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>Template Ready Home Page Design</li>
                    <li>Mobile Responsive Design Ready</li>
                    <li>Customized Inner Pages - 5</li>
                    <li>Custom Contact Form</li>
                    <li> CMS - WordPress (Woo-commerce)</li>
                    <li>10 hours Minor Tweaks To Funtionality</li>
                    <li> Payment Gateway</li>
                    <li> Shipping Module</li>
                    <li>One Page Checkout</li>
                    <li> Social Media + Sitemap + Robot.TXT</li>
                    <li>Custom Video Integration</li>
                    <li>On-Page SEO</li>
                    <li>Full Screen Home Page Slider</li>
                    <li>Featured Products + New Products</li>
                    <li>Most Selling + Recommended Products</li>
                    <li>Parallax Design to Showcase Portfolio</li>
                    <li>Extensions to Fasten The Website</li>
                    <li> Content Optimization</li>
                    <li>Search Engine Friendly URLs</li>
                    <li>Google Local Directory Submissions</li>
                    <li>Google Webmaster & Analytics</li>
                    <li> Free Training</li>
                    <li>Delivery - PSDs, HTML5 Pages</li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    Ecommerce <span>Development</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="pro-icon"></i>MidSize Store
                      </h3>
                      <p>Best For Setup Business</p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="reg_price">
                            <small>$</small>2,499<b>/mo</b>
                          </span>
                        </h4>
                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>Template Ready Home Page Design</li>
                    <li>Mobile Responsive Design Ready</li>
                    <li>Customized Inner Pages - Unlimited</li>
                    <li>Custom Contact Form + RAQ Form</li>
                    <li>CMS - Magento with all functionality</li>
                    <li>25 hours Minor Tweaks To Funtionality</li>
                    <li>Custom Payment Gateway</li>
                    <li>Custom Shipping Module</li>
                    <li>One Page Checkout</li>
                    <li>Social Media + Sitemap + Robot.TXT</li>
                    <li>Custom Video Integration</li>
                    <li>On-Page SEO</li>
                    <li>Full Screen Home Page Slider</li>
                    <li>Featured Products + New Products</li>
                    <li>Most Selling + Recommended Products</li>
                    <li>Parallax Design to Showcase Portfolio</li>
                    <li>Extensions to Fasten The Website</li>
                    <li>Content Optimization</li>
                    <li>Search Engine Friendly URLs</li>
                    <li>Google Local Directory Submissions</li>
                    <li>Google Webmaster & Analytics</li>
                    <li>Free Training</li>
                    <li>Delivery - PSDs, HTML5 Pages</li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    Ecommerce <span>Development</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="advance-icon"></i>Large Store
                      </h3>
                      <p>Best For Big Business</p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="reg_price">
                            <small>$</small>2,899<b>/mo</b>
                          </span>
                        </h4>
                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>Customized Home Page Design</li>
                    <li>Mobile Responsive Design Ready</li>
                    <li>Customized Inner Pages - Unlimited</li>
                    <li>Custom Contact Form + RAQ Form</li>
                    <li>CMS - Magento with all functionality</li>
                    <li>50 hours Minor Tweaks To Funtionality</li>
                    <li>Custom Payment Gateway</li>
                    <li>Custom Shipping Module</li>
                    <li>One Page Checkout</li>
                    <li>Social Media + Sitemap + Robot.TXT</li>
                    <li>Custom Video Integration</li>
                    <li>On-Page SEO</li>
                    <li>Full Screen Home Page Slider</li>
                    <li>Featured Products + New Products</li>
                    <li>Most Selling + Recommended Products</li>
                    <li>Parallax Design to Showcase Portfolio</li>
                    <li>Extensions to Fasten The Website</li>
                    <li>Content Optimization</li>
                    <li>Search Engine Friendly URLs</li>
                    <li>Google Local Directory Submissions</li>
                    <li>Google Webmaster & Analytics</li>
                    <li>Free Training</li>
                    <li>Delivery - PSDs, HTML5 Pages</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container id="title3" className="top_cont">
          <div className="left-padd pt-5">
            <h2 className="h2-title text-black">
              SEO <span>Services</span>
            </h2>
          </div>
          <div className="development_box">
            <Row className="service_row justify-content-center">
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="star-icon"></i>Basic Package
                  </h3>
                  <p>Best For Start-ups</p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="del_price">
                        <del>$ 399</del>/mo
                      </span>
                      <span class="reg_price">
                        <small>$</small>249<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                      <span>Save 46%</span>
                      {/* <h5>Package includes:</h5> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="pro-icon"></i>Pro Package
                  </h3>
                  <p>Best For Setup Business</p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="del_price">
                        <del>$ 799</del>/mo
                      </span>
                      <span class="reg_price">
                        <small>$</small>499<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                      <span>Save 46%</span>
                      {/* <h5>Package includes:</h5> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="service_list development_list">
                  <h3>
                    <i className="advance-icon"></i>Advanced Package
                  </h3>
                  <p>Best For Big Business</p>
                  <div className="select_box">
                    <h4 className="pricemoney">
                      <span class="del_price">
                        <del>$ 1,299</del>/mo
                      </span>
                      <span class="reg_price">
                        <small>$</small>899<b>/mo</b>
                      </span>
                    </h4>
                    <div className="big_btn mt-3">
                      <Link
                        to="/lets-talk"
                        state="Let's Talk For Pricing.."
                        className="btn btn-lg- btn-primary d-block"
                      >
                        Select
                      </Link>
                      <span>Save 36%</span>
                      {/* <h5>Package includes:</h5> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mobile_cont">
          <div className="development_box">
            <Row className="service_row justify-content-center">
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    SEO <span>Services</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="star-icon"></i>Basic Package
                      </h3>
                      <p>Best For Start-ups</p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="del_price">
                            <del>$ 399</del>/mo
                          </span>
                          <span class="reg_price">
                            <small>$</small>249<b>/mo</b>
                          </span>
                        </h4>
                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                          <span>Save 46%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>5 Keywords Research</li>
                    <li>100 Backlinks</li>
                    <li>Site Audit</li>
                    <li>Initial Setup</li>
                    <li>Technical SEO - (Robots.txt, Sitemap)</li>
                    <li>Basic On-page Optimization</li>
                    <li>Content Optimization</li>
                    <li>Monthly Custom Report</li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    SEO <span>Services</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="pro-icon"></i>Pro Package
                      </h3>
                      <p>Best For Setup Business</p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="del_price">
                            <del>$ 799</del>/mo
                          </span>
                          <span class="reg_price">
                            <small>$</small>499<b>/mo</b>
                          </span>
                        </h4>

                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                          <span>Save 46%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>10 Keywords Research</li>
                    <li>200 Backlinks</li>
                    <li>Site Audit</li>
                    <li>Initial Setup & Configuration</li>
                    <li>Technical SEO - (Robots.txt, Sitemap, etc.)</li>
                    <li>On-page Optimization</li>
                    <li>Content Editing & Optimization</li>
                    <li>Monthly Custom Report</li>
                    <li>Internal Link Building (Crosslinking)</li>
                  </ul>
                </div>
              </Col>
              <Col md={6} lg={4}>
                <div className="shadow_mob">
                  <h2 className="h2-title text-black">
                    SEO <span>Services</span>
                  </h2>
                  <div className="service_list development_list">
                    <div className="show_device">
                      <h3>
                        <i className="advance-icon"></i>Advanced Package
                      </h3>
                      <p>Best For Big Business</p>
                      <div className="price_money">
                        <h4 className="pricemoney">
                          <span class="del_price">
                            <del>$ 1,299</del>/mo
                          </span>
                          <span class="reg_price">
                            <small>$</small>899<b>/mo</b>
                          </span>
                        </h4>
                        <div className="big_btn mt-3 mb-3">
                          <Link
                            to="/lets-talk"
                            state="Let's Talk For Pricing.."
                            className="btn btn-lg- btn-primary d-block"
                          >
                            Select
                          </Link>
                          <span>Save 36%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service_list development_list">
                  <h5 className="inc_pack">Package includes:</h5>
                  <ul>
                    <li>25 Keywords Research</li>
                    <li>450 Backlinks</li>
                    <li>Site Audit</li>
                    <li>Initial Setup & Configuration</li>
                    <li>Technical SEO - (Robots.txt, Sitemap, etc.)</li>
                    <li>On-page Optimization</li>
                    <li>Content Editing & Optimization</li>
                    <li>Monthly Custom Report</li>
                    <li>Local Optimization</li>
                    <li>Social Marketing</li>
                    <li>Internal Link Building (Crosslinking)</li>
                    <li>Monthly Personalized Review Call</li>
                    <li>Conversion Optimization</li>
                    <li>Duplicate Content Strategy</li>
                    <li>Lead Generation Focused</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="cnt_section">
        <Container>
          <div className="left-padd">
            <div className="lets_talk_box">
              <Row>
                <Col lg={6}>
                  <h2 className="h2-title big-h2-title">
                    Let’s <span>talk</span>
                  </h2>
                  <div className="fill-form-text">
                    Fill out the form and we’ll get back to you as soon as we
                    can.
                  </div>
                </Col>
                <Col lg={6}>
                  <ContactForm />
                </Col>
              </Row>
              <Row className="reach-us align-items-end">
                <Col lg={12}>
                  <div className="contact_infos">
                    <h3>
                      What’s <br />
                      next?
                    </h3>
                    <ul className="info_list">
                      <li>
                        We’ll contact you within 24 hours with more information
                        on our next steps. In the meantime you can check our
                        Case-Studies.
                      </li>
                      <li>
                        Our next step will be to collect all the requirements
                        for your project, clarify your business objectives and
                        expectations towards our cooperation.
                      </li>
                      <li>After that we’ll develop a proposal for you.</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section className="blank_price_section"></section>
    </>
  );
};

export default Pricing;
