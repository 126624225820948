import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import footerlogo from "../assets/images/footer_logo.png";
import { Link } from "react-router-dom";
import address from "../assets/images/icons/address.svg";
import cnt from "../assets/images/icons/cnt.svg";
import skype from "../assets/images/icons/skype.svg";
import ScrollBtn from "../Helper/ScrollBtn";
import whatsapp from "../assets/images/icons/whatsapp.svg";
const Footer = () => {
  return (
    <div className="footer-main">
      <Container>
        <Row>
          <Col lg={5}>
            <div className="footer-address">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src={footerlogo}
                    alt="footer logo" width={252} height={50}
                    // style={{ maxHeight: "50px" }}
                  />
                </Link>
              </div>
              <div className="footer-box mb-3 ">
                <h3>
                  <i>
                    <img src={address} alt="address" width={19} height={21} />
                  </i>
                  Address
                </h3>
                <p>
                  1st Floor, Create Campus, 11KM, <br />
                  Arakere Bannerghatta Rd, <br />
                  Omkar Nagar, Arekere, <br />
                  Bengaluru, Karnataka 560076
                </p>
              </div>
              <div className="footer-box">
                <h3>
                  <i>
                    <img src={cnt} alt="contact" width={25} height={25} />
                  </i>
                  Contact
                </h3>
                <p className="mb-0">
                  <a href="tel:91 63861 63507">+91 63861 63507</a>
                </p>
                <p className="">
                  <a href="mailto:info@websitemasters.net">
                    info@websitemasters.net
                  </a>
                </p>
              </div>
              <div className="footer-box">
                <h3>
                  <i>
                    <img src={skype} alt="skype" width={25} height={25} />
                  </i>
                  Skype
                </h3>
                <p className="mb-0">
                  <a href="https://join.skype.com/invite/pwaGYaTr9vlt" target="_blank" aria-label="join skype" rel="noopener noreferrer">
                    https://join.skype.com/invite/pwaGYaTr9vlt
                  </a>
                </p>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <Row>
              <Col>
                <div className="footer-box">
                  <h3>Links</h3>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/case-studies">Case Studies</Link>
                    </li>
                    <li>
                      <Link to="/b2b-partnership">
                        <b>B2B Partnership</b>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        Get in Touch
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-blogs">
                        Our Blogs
                      </Link>
                    </li>
                    <li>
                      <Link to="/affliate-form">
                        Become Website Masters Affiliate
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col>
                <div className="footer-box">
                  <h3>Legal</h3>
                  <ul>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col>
                <div className="footer-box">
                  <h3>Follow Us</h3>
                  <ul className="d-flex ul-follow">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/euodiastechnologies/"
                        target="_blank" aria-label="Linkedin" rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin">
                        <img src="./linkedin.svg" alt="linked in" width={30} height={30}/>
                        </i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCSaOLempUZPXmL97saWTJHA"
                        target="_blank" aria-label="Youtube" rel="noopener noreferrer"
                      > 
                        <i className="fa fa-youtube">
                          <img src="./youtube.svg" alt="youtube" width={35} height={35} />
                        </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="copyright mt-4">
        <Container>
          <div className="text-end">
            <address>
              Copyright &copy; 2022{" "}
              <a href="https://euodias.com/" target="_blank" aria-label="Euodias Technologies Pvt. Ltd." rel="noopener noreferrer">
                Euodias Technologies Pvt. Ltd.
              </a>{" "}
              All rights reserved.
            </address>
          </div>
        </Container>
      </div>
      <ScrollBtn />
      <div className="whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=916386163507"
          target="_blank" rel="noopener noreferrer" aria-label="whats app"
        >
          <img src={whatsapp} alt="whatsapp" width={54} height={54}/>
        </a>
      </div>
    </div>
  );
};

export default Footer;
