import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import user from "../assets/images/testimonial/user.webp";
import user from "../assets/images/testimonial/user.jpg";
import jawahar from "../assets/images/testimonial/jawaharji.webp";
import usha from "../assets/images/testimonial/ushaji.webp";
import pranshu from "../assets/images/testimonial/pranshu.webp";
import quote from "../assets/images/testimonial/quote.svg";
// import play from "../assets/images/testimonial/play.svg";
import { Link } from "react-router-dom";

const Testimonial = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="testimonial_carousel">
        <Slider {...settings}>
          <div className="testimonials">
            <div className="testimonial_box">
              <div className="testimonial_text">
                <div className="overs">
                  <span>
                    <img src={quote} alt="quote" width={16} height={14} />
                  </span>
                  <p>
                    Website Masters (Euodias Technologies) did an exceptional
                    job in developing the website of our Company. Thanks to
                    their knowledge and dedication, our website{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://buontuin.com/"
                    >
                      www.buontuin.com
                    </a>{" "}
                    looks great as we envisioned & functions well. Putting
                    together a fashion website is not easy. When I was looking
                    for a Company that has the ability to create, design and
                    optimize my web site, I came across Website Masters, the
                    team’s excellent skillset and suggestions throughout the
                    process helped to improve the overall functionality of the
                    Website.
                  </p>
                  <p>
                    Very impressed with the quality of work and meticulous
                    attention to detail. I highly endorse Website Masters to
                    anyone who is looking to create a customized website.
                  </p>
                </div>
              </div>
            </div>
            <div className="client_box">
              <div className="client_div">
                <img src={usha} alt="client" width={49} height={49} />
                <div className="client_info">
                  <span className="clnt_name">Usha Bala</span>
                  <span className="clnt_role">Buon Tuin, Founder</span>
                </div>
              </div>
              <div className="case_box">
                <Link to="/case/buontuin">Read Case Study {">"}</Link>
              </div>
            </div>
          </div>
          <div className="testimonials">
            <div className="testimonial_box">
              <div className="testimonial_text">
                <div className="overs">
                  <span>
                    <img src={quote} alt="quote" width={16} height={14} />
                  </span>
                  <p>
                    It was my first time getting a website done and among so
                    many companies to choose from, I can certainly say Website
                    Masters was the perfect choice. Their design and development
                    experts gave me exactly what I wanted. Fast, time-bound, and
                    credible in their approach. Thank you Website Masters. You
                    guys are really Masters and I hope you continue to thrive.
                  </p>
                </div>
              </div>
            </div>
            <div className="client_box">
              <div className="client_div">
                <img src={pranshu} alt="client" width={49} height={49} />
                <div className="client_info">
                  <span className="clnt_name">Pranshu Sahu</span>
                  <span className="clnt_role">Vortex Solution, CEO</span>
                </div>
              </div>
              <div className="case_box">
                <Link to="/case/vortex">Read Case Study {">"}</Link>
              </div>
            </div>
          </div>
          <div className="testimonials">
            <div className="testimonial_box">
              <div className="testimonial_text">
                <div className="overs">
                  <span>
                    <img src={quote} alt="quote" width={16} height={14} />
                  </span>
                  <p>
                    I was looking for a professional design and branding for our
                    food startup. It was a pleasant and seamless working with
                    Website Masters as I got more than what I initially wanted.
                    Cheers!
                  </p>
                </div>
              </div>
            </div>
            <div className="client_box">
              <div className="client_div">
                <img src={jawahar} alt="client" width={49} height={49} />
                <div className="client_info">
                  <span className="clnt_name">Jawahar Jamaison</span>
                  <span className="clnt_role">Beribites, Founder and CEO</span>
                </div>
              </div>
              {/* <div className="case_box">
                <Link to="/">Read Case Study {">"}</Link>
              </div> */}
            </div>
          </div>
          <div className="testimonials">
            <div className="testimonial_box">
              <div className="testimonial_text">
                <div className="overs">
                  <span>
                    <img src={quote} alt="quote" width={16} height={14} />
                  </span>
                  <p>Great work, polite and timely delivery. Thank you</p>
                </div>
              </div>
            </div>
            <div className="client_box">
              <div className="client_div">
                <img src={user} alt="client" width={49} height={49} />
                <div className="client_info">
                  <span className="clnt_name">Nabeen</span>
                  <span className="clnt_role">The Human Power, Founder</span>
                </div>
              </div>
              <div className="case_box">
                <Link to="/case/the-human-power">Read Case Study {">"}</Link>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Testimonial;
