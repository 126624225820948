import React from "react";
import { Container, Row, Col } from "react-bootstrap";
const Terms = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Terms & Conditions</h1>
          </div>
        </Container>
      </section>
      <section className="section case-section">
        <Container>
          <div className="left-padd">
            <h2>
              The following Terms and Conditions will apply to the use of any{" "}
              <b>Website Mastser</b> services beginning on 2012, though you are
              invited to review and accept its terms at any time before such
              date.
            </h2>
            <div className="mt-4">
              <h2>
                <strong>1. Terms</strong>
              </h2>
              <p>
                By accessing this website, you are agreeing to be bound by these
                website’s Terms and Conditions of Use, all applicable laws and
                regulations, and agree that you are responsible for compliance
                with any applicable local laws. If you do not agree with any of
                these terms, you are prohibited from using or accessing this
                site. The materials contained in this website are protected by
                applicable copyright and trade mark law.
              </p>
              <h2>
                <strong>2. Use License</strong>
              </h2>
              <p>
                Permission is granted to temporarily access the materials
                (information or software) on <b>Website Mastser's</b> website
                for personal, non-commercial transitory viewing only. This is
                the grant of a license, not a transfer of title, and under this
                license you may not:
              </p>
              <ul role="list">
                <li>Modify or copy the materials;</li>
                <li>
                  Use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </li>
                <li>
                  Attempt to decompile or reverse engineer any software
                  contained on <b>Website Mastser's</b> website;
                </li>
                <li>
                  Remove any copyright or other proprietary notations from the
                  materials; or transfer the materials to another person or
                  “mirror” the materials on any other server.
                </li>
              </ul>
              <p>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by{" "}
                <b>Website Mastser</b> at any time. Upon terminating your
                viewing of these materials or upon the termination of this
                license, you must destroy any downloaded materials in your
                possession whether in electronic or printed format.
              </p>
              <h2>
                <strong>3. Disclaimer</strong>
              </h2>
              <p>
                The materials on <b>Website Mastser's</b> website are provided
                “as is”. <b>Website Mastser</b> makes no warranties, expressed
                or implied, and hereby disclaims and negates all other
                warranties, including without limitation, implied warranties or
                conditions of merchantability, fitness for a particular purpose,
                or non-infringement of intellectual property or other violation
                of rights. Further, <b>Website Mastser</b> does not warrant or
                make any representations concerning the accuracy, likely
                results, or reliability of the use of the materials on its
                Internet website or otherwise relating to such materials or on
                any sites linked to this website.
              </p>
              <h2>
                <strong>4. Limitations</strong>
              </h2>
              <p>
                In no event shall <b>Website Mastser</b> or its suppliers (IT
                Services) be liable for any damages (including, without
                limitation, damages for loss of data or profit, or due to
                business interruption,) arising out of the use or inability to
                use the services provided by <b>Website Mastser</b>, even if{" "}
                <b>Website Mastser</b> or a <b>Website Mastser</b> authorized
                representative has been notified orally or in writing of the
                possibility of such damage. Because some jurisdictions do not
                allow limitations on implied warranties, or limitations of
                liability for consequential or incidental damages, these
                limitations may not apply to you.
              </p>
              <h2>
                <strong>5. Revisions and Errata</strong>
              </h2>
              <p>
                The materials appearing on <b>Website Mastser's</b> website
                could include technical, typographical, or photographic errors.{" "}
                <b>Website Mastser</b> does not warrant that any of the
                materials on its website are accurate, complete, or current.{" "}
                <b>Website Mastser</b> may make changes to the materials
                contained on its website at any time without notice.{" "}
                <b>Website Mastser</b> does not, however, make any commitment to
                update the materials.
              </p>
              <h2>
                <strong>6. Links</strong>
              </h2>
              <p>
                In no event shall <b>Website Mastser</b> or its suppliers (IT
                Services) be liable for any damages (including, without
                limitation, damages for loss of data or profit, or due to
                business interruption,) arising out of the use or inability to
                use the services provided by <b>Website Mastser</b>, even if{" "}
                <b>Website Mastser</b> or a <b>Website Mastser</b> authorized
                representative has been notified orally or in writing of the
                possibility of such damage. Because some jurisdictions do not
                allow limitations on implied warranties, or limitations of
                liability for consequential or incidental damages, these
                limitations may not apply to you.
              </p>
              <h2>
                <strong>7. Site Terms of Use Modifications</strong>
              </h2>
              <p>
                Amendments to this Terms and Conditions may be made at any time
                and you should check back frequently for any changes. This
                policy was last updated on Oct 2022.
              </p>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Terms;
