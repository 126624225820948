import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { useLocation } from "react-router-dom";
const LetsForm = () => {
  const location = useLocation();
  let values = location.state;
  values = location.state == null ? "Let's TALK" : location.state;
  const [show, setShow] = useState(false);
  let apiBaseUrl = window.location.hostname;
  if (apiBaseUrl == "localhost") {
    apiBaseUrl = "";
  } else {
    apiBaseUrl = "https://api.websitemasters.net";
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    setShow(true);
    axios({
      method: "post", //put
      url: apiBaseUrl + "/api/b2b_partnership/send_email_b2b",
      data: {
        user: "Jasvinder.wm@gmail.com",
        pass: "chuaoxqjxejybkgu",
        html:
          `<table style="border:1px solid #ccc;border-collapse: collapse; width:500px;">
        <thead><tr><th colspan="2" style="padding:8px 12px; text-align:center;border:1px solid #ccc"><b>Form Details</b></th></tr>
        </thead>
        <tbody>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">First Name:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.f_name +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Last Name:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.l_name +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Business Email:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.b_email +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Mobile Number:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.mobile +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Designation:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.designation +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Company Name:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.company_name +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Company URL:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.company_url +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Description:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.description +
          `</td></tr>
        </tbody>
        </table>`,
        to_email: "joevam@gmail.com,codesbyarsh@gmail.com", //
        subject: values,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log(response);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
    reset();
  };
  if (show) {
    return (
      <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Thanks for being awesome! </Alert.Heading>
        <p>
          We have received your message and would like to thank you for writing
          to us. If your inquiry is urgent, please use the telephone number
          listed below to talk to one of our staff members.
        </p>
        <p>Otherwise, we will reply by email as soon as possible.</p>
      </Alert>
    );
  }
  return (
    <Form
      className="contact_form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="form-group">
        <label htmlFor="firstname">First Name</label>
        <input
          id="firstname"
          autoComplete="false"
          className="form-control"
          type="text"
          {...register("f_name", {
            required: true,
            maxLength: 50,
          })}
        />
        {errors.f_name && (
          <p className="text-error">Please check the First Name</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="lastname">Last Name</label>
        <input
          id="lastname"
          autoComplete="false"
          className="form-control"
          type="text"
          {...register("l_name", {
            required: true,
            maxLength: 50,
          })}
        />
        {errors.l_name && (
          <p className="text-error">Please check the Last Name</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="business">Business Email</label>
        <input
          id="business"
          autoComplete="false"
          className="form-control"
          type="email"
          {...register("b_email", {
            required: true,
            maxLength: 50,
          })}
        />
        {errors.b_email && <p className="text-error">Please check the Email</p>}
      </div>
      <div className="form-group">
        <label htmlFor="mobile">Mobile Number</label>
        <input
          id="mobile"
          autoComplete="false"
          className="form-control"
          type="number"
          {...register("mobile", {
            required: true,
            maxLength: 10,
          })}
        />
        {errors.mobile && (
          <p className="text-error">
            Please check the Mobile Number(10 digit accepted)
          </p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="designation">Designation</label>
        <input
          id="designation"
          autoComplete="false"
          className="form-control"
          type="text"
          {...register("designation", {
            required: true,
            maxLength: 50,
          })}
        />
        {errors.designation && (
          <p className="text-error">Please check the Designation</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="companyname">Company Name</label>
        <input
          id="companyname"
          autoComplete="false"
          className="form-control"
          type="text"
          {...register("company_name", {
            required: true,
            maxLength: 50,
          })}
        />
        {errors.company_name && (
          <p className="text-error">Please check the Company Name</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="companyurl">Company URL</label>
        <input
          id="companyurl"
          autoComplete="false"
          className="form-control"
          type="text"
          {...register("company_url", {
            required: true,
            maxLength: 50,
          })}
        />
        {errors.company_url && (
          <p className="text-error">Please check the Company URL</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="help">How can we help you?</label>
        <textarea
          id="help"
          autoComplete="false"
          className="form-control"
          {...register("description", {
            required: true,
            maxLength: 300,
          })}
        ></textarea>
        {errors.description && (
          <p className="text-error">Please check the Description</p>
        )}
      </div>
      <div className="list-btn btn_2 btn_cut">
        <button type="submit" className="submit_btn">
          <svg>
            <rect x="0" y="0" fill="none" width="100%" height="100%" />
          </svg>
          Submit
        </button>
      </div>
    </Form>
  );
};

export default LetsForm;
