import React, {useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
const ContactForm = () => {
  const [show, setShow] = useState(false);
  let apiBaseUrl = window.location.hostname;
  if (apiBaseUrl == "localhost") {
    apiBaseUrl = "";
  } else {
    apiBaseUrl = "https://api.websitemasters.net";
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setShow(true);
    console.log(data);
    axios({
      method: "post", //put
      url: apiBaseUrl + "/send_email",

      data: {
        user: "Jasvinder.wm@gmail.com",
        pass: "chuaoxqjxejybkgu",
        html: `<table style="border:1px solid #ccc;border-collapse: collapse; width:500px;">
        <thead><tr><th colspan="2" style="padding:8px 12px; text-align:center;border:1px solid #ccc">Form Details</th></tr>
        </thead>
        <tbody>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Name:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">`+data.name+`</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Mobile:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">`+data.mobile+`</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Email:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">`+data.email+`</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Description:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">`+data.description+`</td></tr>
        </tbody>
        </table>`,
        to_email: "joevam@gmail.com,codesbyarsh@gmail.com",
        subject: 'Have a PROJECT?',
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log(response);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
    reset();
  };

  if (show) {
    return (
      <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Thanks for being awesome! </Alert.Heading>
        <p>
          We have received your message and would like to thank you for writing
          to us. If your inquiry is urgent, please use the telephone number
          listed below to talk to one of our staff members.
        </p>
        <p>Otherwise, we will reply by email as soon as possible.</p>
      </Alert>
    );
  }
  return (
    <Form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="name">Your Name</label>
        <input id="name"
          className="form-control"
          type="text"
          {...register("name", {
            required: true,
            maxLength: 50,
          })}
        />
        {errors.name && <p className="text-error">Please check the Name</p>}
      </div>
      <div className="form-group">
        <label htmlFor="number">Mobile Number</label>
        <input id="number"
          className="form-control"
          type="number"
          {...register("mobile", {
            required: true,
            maxLength: 14,
          })}
        />
        {errors.mobile && (
          <p className="text-error">Please check the Mobile Number</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input id="email"
          className="form-control"
          type="email"
          {...register("email", {
            required: true,
            maxLength: 50,
          })}
        />
        {errors.email && <p className="text-error">Please check the Email</p>}
      </div>
      <div className="form-group">
        <label htmlFor="brief">Please let us know in brief</label>
        <textarea id="brief"
          className="form-control"
          {...register("description", {
            required: true,
            maxLength: 300,
          })}
        ></textarea>
        {errors.description && (
          <p className="text-error">Please check the Description</p>
        )}
      </div>
      <div className="list-btn btn_2 btn_cut">
        <button type="submit" className="submit_btn">
          <svg>
            <rect x="0" y="0" fill="none" width="100%" height="100%" />
          </svg>
          Submit
        </button>
      </div>
    </Form>
  );
};

export default ContactForm;
