import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Layout/Main";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Portfolio from "./Pages/Portfolio";
import Pricing from "./Pages/Pricing";
import CaseStudies from "./Pages/CaseStudies";
import Contact from "./Pages/Contact";
import Hilforte from "./Pages/Hilforte";
import Prylin from "./Pages/Prylin";
import ActionPlus from "./Pages/ActionPlus";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import ScrollToTop from "./Helper/ScrollToTop";
import Vortex from "./Pages/Vortex";
import Buontuin from "./Pages/Buontuin";
import Thehumanpower from "./Pages/Thehumanpower";
import LetsTalk from "./Pages/LetsTalk";
import MobilePrivacy from "./Pages/MobilePrivacy";
import OurBlogs from "./Pages/OurBlogs";
import BlogDetails from "./Pages/BlogDetails";
import Affliateform from "./Pages/AffliateForm";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="b2b-partnership" element={<Portfolio />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="contact" element={<Contact />} />
          <Route path="our-blogs" element={<OurBlogs />} />
          <Route path="affliate-form" element={<Affliateform />} />
          <Route path="blog-details/:id" element={<BlogDetails />} />
          <Route path="case-studies" element={<CaseStudies />} />
          <Route path="case/hilforte" element={<Hilforte />} />
          <Route path="case/prylin" element={<Prylin />} />
          <Route path="case/action-plus" element={<ActionPlus />} />
          <Route path="case/vortex" element={<Vortex />} />
          <Route path="case/buontuin" element={<Buontuin />} />
          <Route path="case/the-human-power" element={<Thehumanpower />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="privacy-policy-mobile" element={<MobilePrivacy />} />
          <Route path="lets-talk" element={<LetsTalk />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
