import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import arr from "../assets/images/icons/white-rrow.svg";
import pie from "../assets/images/service-pie.webp";
import Helmet from "react-helmet";
const Services = () => {
  return (
    <>
      <Helmet>
        <title>Masters - Web Design&Development Agency</title>
        <meta
          name="description"
          content="Website Masters is a professional web, mobile design & development company in India. We offer a wide array of services to cater to your requirements. Contact us Now!"
        />
      </Helmet>
      <section className="service-wrapper">
        <section className="section top-banner">
          <Container>
            <div className="banner-box">
              <h1 className="mb-3">Our Services</h1>
            </div>
          </Container>
        </section>

        <section className="services-list">
          <Container>
            <div className="service_box">
              <Row className="service_row">
                <Col md={6}>
                  <div className="service_list">
                    <h3>Web Development</h3>
                    <ul>
                      <li>PHP Development</li>
                      <li>Laravel Development</li>
                      <li>Codelgniter Development</li>
                      <li>Drupal Development</li>
                      <li>E-commerce Development</li>
                      <li>Wordpress Development</li>
                      <li>Wordpress Plugin Development</li>
                      <li>WooCommerce Development</li>
                      <li>Magento 2.0 Development</li>
                      <li>OpenCart Development</li>
                      <li>Shopity Development</li>
                      <li>Custom Website Design</li>
                    </ul>
                    <div className="service_btn">
                      <div className="list-btn btn_2">
                        <Link
                          to="/lets-talk"
                          state="Services (Let's Discuss)"
                          className="submit_btn"
                        >
                          <svg>
                            <rect
                              x="0"
                              y="0"
                              fill="none"
                              width="100%"
                              height="100%"
                            />
                          </svg>
                          Let’s Discuss
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="service_list">
                    <h3>Mobile App Development</h3>
                    <ul>
                      <li>iPhone App Development</li>
                      <li>Android App Development</li>
                      <li>Ionic App Development</li>
                      <li>React Native App Development</li>
                    </ul>
                    <div className="service_btn">
                      <div className="list-btn btn_2">
                        <Link
                          to="/lets-talk"
                          state="Services (Let's Discuss)"
                          className="submit_btn"
                        >
                          <svg>
                            <rect
                              x="0"
                              y="0"
                              fill="none"
                              width="100%"
                              height="100%"
                            />
                          </svg>
                          Let’s Discuss
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="service_list">
                    <h3>Hire Dedicated Resource</h3>
                    <ul>
                      <li>Hire PHP Developer</li>
                      <li>Hire Laravel Developer</li>
                      <li>Hire Codelgniter Developer</li>
                      <li>Hire E-commerce Developer</li>
                      <li>Hire Wordpress Developer</li>
                      <li>Hire Magento Developer</li>
                      <li>Hire Drupal Developer</li>
                      <li>Hire WooCommerce Developer</li>
                      <li>Hire React Native Developers</li>
                      <li>Hire Angular Developers</li>
                      <li>Hire Node Js Developers</li>
                      <li>Hire Full Stack Web Developers</li>
                      <li>Hire Shopity Developers</li>
                      <li>Hire Software Developer</li>
                      <li>Hire Web Developer</li>
                    </ul>
                    <div className="service_btn">
                      <div className="list-btn btn_2">
                        <Link
                          to="/lets-talk"
                          state="Services (Let's Discuss)"
                          className="submit_btn"
                        >
                          <svg>
                            <rect
                              x="0"
                              y="0"
                              fill="none"
                              width="100%"
                              height="100%"
                            />
                          </svg>
                          Let’s Discuss
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="service_list">
                    <h3>Other Services</h3>
                    <ul>
                      <li>Real Estate Website Development</li>
                      <li>Taxi Booking App Development</li>
                      <li>Zillow Clone</li>
                      <li>Social Networking Website Development</li>
                      <li>Linkedin Clone</li>
                      <li>SEO Services</li>
                      <li>Trulia Clone</li>
                      <li>Facebook Clone</li>
                      <li>Web Application Development</li>
                      <li>Software Development Services</li>
                      <li>Website Design Services</li>
                      <li>Dating Website Development</li>
                      <li>Classified Website Development</li>
                      <li>Job Portal Website Development</li>
                      <li>Matrimony Website Development</li>
                      <li>Travel Website Development</li>
                      <li>Automotive Website Development</li>
                      <li>Multi Vendor Website Development</li>
                    </ul>
                    <div className="service_btn">
                      <div className="list-btn btn_2">
                        <Link
                          to="/lets-talk"
                          state="Services (Let's Discuss)"
                          className="submit_btn"
                        >
                          <svg>
                            <rect
                              x="0"
                              y="0"
                              fill="none"
                              width="100%"
                              height="100%"
                            />
                          </svg>
                          Let’s Discuss
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="common-blue-box blue_box_development">
                <div className="development_boxs">
                  <div className="develop_text">
                    <h2 className="h2-title text-start d-inline-block">
                      <b style={{ whiteSpace: "nowrap" }}>Our Development</b>{" "}
                      <span
                        className="d-block"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Process
                      </span>
                    </h2>
                  </div>
                  <div className="pie_chart">
                    <img
                      src={pie}
                      alt="pie"
                      className="img-fluid"
                      width={692}
                      height={692}
                    />
                  </div>
                </div>
                <section className="opportunities">
                  <div className="opportunities_box">
                    <h2 className="h2-title text-black">
                      Discover new opportunities
                      <span className="d-block">for your business</span>
                    </h2>
                    <div className="opportunities_btn big_btn">
                      <Link
                        to="/lets-talk"
                        state="Schedule an Appointment"
                        className="btn btn-lg- btn-primary"
                      >
                        Schedule an Appointment{" "}
                        <img src={arr} alt="arrow" width={27} height={8} />
                      </Link>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Container>
        </section>
      </section>
    </>
  );
};

export default Services;
