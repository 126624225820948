import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import b2bimg from "../assets/images/b2btalk.webp";
import arr from "../assets/images/icons/white-rrow.svg";
const Portfolio = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box">
            <h1 className="mb-3">
              B2B <span className="text-capitalize">Partnership</span>
            </h1>
            <p className="banner-p pe-5">
              We partner with non Software Development Companies to empower them
              to grow exponentially.
            </p>
          </div>
        </Container>
      </section>
      <section className="portfolio-section">
        <Container>
          <div className="blue-box laptop_bluebox">
            <div className="b2b_img">
              <img
                src={b2bimg}
                alt="b2b"
                className="img-fluid"
                width={589}
                height={427}
              />
            </div>
            <div className="b2b_text">
              <h2 className="h2-title">
                Let’s talk <span className="d-block">Technology</span>
              </h2>
              <p className="text-white">
                Choose from our list of focus areas below to learn more.
              </p>
            </div>
          </div>
          <div className="portfolio_list">
            <ul>
              <li>
                <h4>Partnering with non Software Development Companies</h4>
                <p>
                  We provide 24x7 support to companies that do not provide
                  Software Development as a full-fledged service but offer
                  services that are inclined to website development. Such
                  companies could be Digital Marketing Enterprises, Advertising
                  Agencies, or Growth/Marketing Partners.
                </p>
                <p>
                  Feel free to <Link to="/lets-talk"
                    state="B2B Partnership (Let's Talk)">talk to us</Link> if you
                  have a similar requirement.
                </p>
              </li>
              <li>
                <h4>
                  Business Consulting <br />
                  Services
                </h4>
                <p>
                  We are an award-winning IT services company offering worldwide
                  professional technology consulting and business IT solutions
                  for over 10 years.
                </p>
              </li>
              <li>
                <h4>
                  Business <br />
                  Transformation <br />
                  Consulting
                </h4>
                <p>
                  Adapt and adjust your business to anticipate future trends and
                  deliver exactly what your customers need. Business Technology
                  Consulting Take your business beyond best-in-class with
                  incredible technology and expert implementation.
                </p>
              </li>
              <li>
                <h4>
                  Technology Risk <br />
                  Assessment <br />
                  Consulting{" "}
                </h4>
                <p>
                  New risks are emerging all the time. We’ll help you assess
                  your technology and establish a strong defense so you’ll be
                  prepared for anything.
                </p>
              </li>
              <li>
                <h4>
                  Compliance Security <br />
                  Consulting
                </h4>
                <p>
                  Take action now to protect your business. We can help with
                  extensive security updates and relevant compliance
                  documentation and implementation.
                </p>
              </li>
              <li>
                <h4>
                  Growth Strategy <br />
                  Planning Consulting{" "}
                </h4>
                <p>
                  Receive trusted guidance from our experts on how to drive your
                  business’s growth and achieve your greatest goals.
                </p>
              </li>
              <li>
                <h4>
                  Staffing Recruiting <br />
                  Consulting{" "}
                </h4>
                <p>
                  Receive expert counsel to increase your recruitment marketing
                  efforts and easily connect with the best potential talent.
                </p>
              </li>
              <li>
                <h4>Sales Consulting </h4>
                <p>
                  Empower your salesforce to respond quickly, impress potential
                  clients, and follow up personally to increase the amount and
                  quality of sales.
                </p>
              </li>
              <li>
                <h4>
                  Operations <br />
                  Consulting{" "}
                </h4>
                <p>
                  Streamline your operations and attain greater efficiency,
                  productivity, quality, and customer service for your business.
                </p>
              </li>

              <li>
                <h4>
                  Due Diligence <br />
                  Consulting{" "}
                </h4>
                <p>
                  Looking to acquire or partner with another business? We’ll
                  perform a due diligence assessment and provide a clear
                  snapshot of the costs and benefits.
                </p>
              </li>
            </ul>
          </div>
          <div className="common-blue-box blue-opportunities">
            <div className=" text-end">
              <h2 className="h2-title text-start d-inline-block">
                Our <span>Process</span>
              </h2>
            </div>
            <div className="common-text">
              <p className="pt-4">
                We believe in taking a consulting-first approach to ensure you
                get the most value for your solution.
              </p>
            </div>
            <div className="process_list">
              <ul>
                <li>
                  <h4>Listen</h4>
                  <p>
                    Tell us about your goals, past efforts, and existing
                    processes.
                  </p>
                </li>
                <li>
                  <h4>Research</h4>
                  <p>
                    Our team explores available solutions to determine what will
                    work best.
                  </p>
                </li>
                <li>
                  <h4>Advocate</h4>
                  <p>
                    You’ll get a prioritized list of recommendations we believe
                    in.
                  </p>
                </li>
              </ul>
            </div>
            <section className="opportunities b2b_opportunities">
              <div className="opportunities_box">
                <h2 className="h2-title text-black">
                  Discover new opportunities
                  <span className="d-block">for your business</span>
                </h2>
                <div className="opportunities_btn big_btn">
                  <Link
                    className="btn btn-lg- btn-primary"
                    to="/lets-talk"
                    state="Schedule an Appointment"
                  >
                    Schedule an Appointment{" "}
                    <img src={arr} alt="arrow" width={27} height={8} />
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Portfolio;
