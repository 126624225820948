import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
const Main = () => {
  return (
    <div className="wrapper">
      <Header>Header</Header>
      <main className="main-container">
        <Outlet />
      </main>
      <Footer>Footer</Footer>
    </div>
  );
};

export default Main;
