import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import case1 from "../assets/images/projects/hilforte.webp";
import case2 from "../assets/images/projects/prilyn.webp";
import case3 from "../assets/images/projects/action_plus.webp";
import case4 from "../assets/images/projects/vortex.webp";
import case5 from "../assets/images/projects/buontuin.webp";
import case6 from "../assets/images/projects/thehumanpower.webp";
import Helmet from "react-helmet";
const CaseStudies = () => {
  return (
    <>
      <Helmet>
        <title>Case study – Website Masters Clients</title>
        <meta
          name="description"
          content="Website design, web development and marketing case studies, that illustrate some of our expert team’s client success stories."
        />
      </Helmet>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Case Studies</h1>
            <p>
              Our clientele is diverse in terms of industries with an equally
              diverse range of peculiarity from individuals to MSMEs, Startups,
              and established brands. We are privileged to have worked with
              clients from the US, UK, Europe, and the middle-east, adding value
              through finely crafted software development solutions for their
              business.{" "}
            </p>
            <p>
              With varying expectations of clients from different industries,
              we’ve learnt the art of meeting those requirements with precision.
            </p>
          </div>
        </Container>
      </section>
      <section className="section case-section">
        <Container>
          <div className="left-padd">
            <h2>
              Here is a short selection of case studies from different
              industries who’ve benefited from our software development
              services:
            </h2>
          </div>
        </Container>
        <div className="case_container">
          <Container>
            <Row>
              <Col lg={3}>
                <div className="case_div">
                  <Link to="/case/hilforte">
                    <img
                      src={case1}
                      alt="case study"
                      width={339}
                      height={534}
                    />
                    <div className="case_heads text-black">
                      <h3>
                        Case <span>Study</span>
                      </h3>
                      <h4>Hilforte</h4>
                      <p>
                        Real estate/ <br />
                        Construction{" "}
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col lg={3}>
                <div className="case_div">
                  <Link to="/case/prylin">
                    <img
                      src={case2}
                      alt="case study"
                      width={339}
                      height={534}
                    />
                    <div className="case_heads text-white">
                      <h3>
                        Case <span>Study</span>
                      </h3>
                      <h4>Prilyn</h4>
                      <p className="text-white">
                        Clothing <br />
                        Marketplace
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col lg={3}>
                <div className="case_div">
                  <Link to="/case/action-plus">
                    <img
                      src={case3}
                      alt="case study"
                      width={339}
                      height={534}
                    />
                    <div className="case_heads text-black">
                      <h3>
                        Case <span>Study</span>
                      </h3>
                      <h4>Action Plus</h4>
                      <p>
                        Domestic <br />
                        Help
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col lg={3}>
                <div className="case_div">
                  <Link to="/case/vortex">
                    <img
                      src={case4}
                      alt="case study"
                      width={339}
                      height={534}
                    />
                    <div className="case_heads text-white">
                      <h3>
                        Case <span>Study</span>
                      </h3>
                      <h4>Vortex</h4>
                      <p className="text-white">E-Commerce</p>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col lg={3}>
                <div className="case_div">
                  <Link to="/case/buontuin">
                    <img
                      src={case5}
                      alt="case study"
                      width={339}
                      height={534}
                    />
                    <div className="case_heads text-white">
                      <h3>
                        Case <span>Study</span>
                      </h3>
                      <h4>Buontuin</h4>
                      <p className="text-white">
                        Fashion
                        <br />
                        Design
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col lg={3}>
                <div className="case_div">
                  <Link to="/case/the-human-power">
                    <img
                      src={case6}
                      alt="case study"
                      width={339}
                      height={534}
                    />
                    <div className="case_heads text-black">
                      <h3>
                        Case <span>Study</span>
                      </h3>
                      <h4>The Human <br/>Power</h4>
                      <p className="">
                      Recruitment
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default CaseStudies;
