import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const MobilePrivacy = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Privacy Policy for Mobile App</h1>
          </div>
        </Container>
      </section>
      <section className="section case-section">
        <Container>
          <div className="left-padd">
            <h2>PRIVACY POLICY FOR MOBILE APPLICATION “The Human Power”</h2>
            <p>Last updated: November 04, 2022</p>
            <p>
              This privacy policy governs your use of the software application
              "The Human Power" for mobile devices that was created by <a href="https://euodias.com/">Euodias
              Technologies Pvt. Ltd</a>.
            </p>
            <p>
              The Application is a mobile Android application to be used by the
              candidates to submit the resume and relevant documents, no
              signup/registration is required. Anyone can submit.{" "}
            </p>
            <p>
              <strong>
                What information does the Application obtain and how is it used?
              </strong>
            </p>
            <p>
              <strong style={{ color: "#4B75DA" }}>
                User Provided Information
              </strong>
            </p>
            <p>
              We collect information about you when you use our application. We
              receive and store this information when you send it to us on our
              application.
            </p>
            <p>
              We collect information when you choose to give it to us. This
              includes:
            </p>
            <p>
              Your personal information like name, email address, postal
              address, phone number, country, father’s name and mother’s name.
            </p>
            <p>
              Your work experience, education, skills and the job you applied
              for.
            </p>
            <p>Your image that you upload.</p>
            <p>Your passport that you upload, front and back pages.</p>
            <p>
              Your Police Clearance that you upload, front and back pages,
              should you choose to give it.
            </p>
            <p>
              We use the information we gather on the web site for the purposes
              of:
            </p>
            <ul>
              <li>
                <strong>Providing services that we offer to jobseekers</strong>
              </li>
              <li>
                <strong>Operating and improving the application;</strong>
              </li>
              <li>
                <strong>
                  To contact and communicate with you to provide our services.
                </strong>
              </li>
            </ul>

            <p>
              <strong style={{ color: "#4B75DA" }}>
                Automatically Collected Information
              </strong>
            </p>
            <p>
              The Application does not collect any information automatically.
            </p>
            <p>
              The Application does not share any information automatically with
              third parties.
            </p>

            <p>
              <strong>
                Does the Application collect precise real time location
                information of the device?
              </strong>
              <br />
              This Application does not collect precise information about the
              location of your mobile device.
            </p>

            <p>
              <strong>
                Do third parties see and/or have access to information obtained
                by the Application?
              </strong>
              <br />
              Not applicable since the Application does not collect any
              information automatically.
            </p>

            <p>
              <strong>Data Retention Policy, Managing Your Information</strong>
              <br />
              We will retain User provided data for as long as you use the
              Application and for a reasonable time thereafter. If you’d like us
              to delete User Provided Data that you have provided via the
              Application, please contact us at{" "}
              <a href="mailto:info@thehumanpower.com">
                info@thehumanpower.com
              </a>{" "}
              and we will respond in a reasonable time.
            </p>

            <p>
              <strong>Children</strong>
              <br />
              We do not use the Application to knowingly solicit data from or
              market to children under the age of 13. If a parent or guardian
              becomes aware that his or her child has provided us with
              information without their consent, he or she should contact us at{" "}
              <a href="mailto:info@thehumanpower.com">info@thehumanpower.com</a>
              . We will delete such information from our files within a
              reasonable time.
            </p>

            <p>
              <strong>Security</strong>
            </p>
            <p>
              We are concerned about safeguarding the confidentiality of your
              information stored locally on your mobile device.
            </p>
            <p>
              Please be aware that, although we endeavor provide reasonable
              security for information we process and maintain, no security
              system can prevent all potential security breaches (e.g., a
              zero-day attack targeting mobile Operating System – OS).
            </p>
            <p>
              In order to ensure an optimal security, you shall regularly
              install any Application’s update available from the mobile
              application marketplace.
            </p>
            <p>
              We also advise you to regularly check for any Android OS update(s)
              that could be available and to enable automatic updates.
            </p>

            <p>
              <strong>Changes</strong>
            </p>
            <p>
              This Privacy Policy may be updated from time to time for any
              reason. We will notify you of any changes to our Privacy Policy by
              posting the new Privacy Policy at this URL and update the "Last
              updated" date at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes, as continued use is deemed approval of all changes.
              Changes to this Privacy Policy are effective when they are posted
              on this page.
            </p>

            <p>
              <strong>Your Consent</strong>
            </p>
            <p>
              By using the Application, you only consent to us storing and
              processing of your information.
            </p>
            <p>
              At any time, no User Provided Data will be shared, processed
              and/or stored by third parties. User Provided Data cannot and will
              never be sold to any other third parties.
            </p>

            <p>
              <strong>Contact Us</strong>
            </p>
            <p>
              If you have any questions regarding privacy while using the
              Application, or have questions about our practices, please contact
              us via email at{" "}
              <a href="mailto:info@thehumanpower.com">info@thehumanpower.com</a>
              .
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MobilePrivacy;
