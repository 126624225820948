import React from "react";
import { Container } from "react-bootstrap";
import hilforte from "../assets/images/projects/human-power.webp";
import php from "../assets/images/projects/icons/php.svg";
import mysql from "../assets/images/projects/icons/mysql.svg";
import react from "../assets/images/projects/icons/react.svg";
import { Link } from "react-router-dom";
const Thehumanpower = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Case Study</h1>
          </div>
        </Container>
      </section>
      <section className="section single_section">
        <Container>
          <div className="single_padd left-padd">
            <div className="single_text">
              <h2>The human power</h2>
              <p>
                The human power is a recruitment agency that connects employers
                and job seekers while establishing a relationship between them.
              </p>
              <p>
                The company was started in 2000 and has seen several transitions
                over the years and has expanded its operations into 6 different
                countries. Their upcoming goal is to extend to another 6 nations
                to make prospective employment opportunities available.
              </p>
              <div className="single_box">
                <h3>Objective</h3>
                <p>
                  To create and install an android application that promotes
                  efficiency, productivity, and speed in their internal
                  operational structure as carrying out every process physically
                  was tedious and time-consuming.
                </p>
              </div>
              <div className="single_box">
                <h3>Our Solution</h3>
                <p>
                  Website Masters developed an online platform that directs all
                  the enquiries with regards to job-seekers and employers to an
                  email Id where all the information is sorted and presented to
                  the client according to the prescribed parameters.
                </p>
              </div>
              <div className="single_image">
                <img
                  src={hilforte}
                  alt="single project"
                  width={978}
                  height={666}
                  className="img-fluid"
                />
              </div>
              <div className="single_box">
                <h3>Features</h3>
                <ul>
                  <li>
                    <span>
                      Collecting candidate’s documents, CV, and other relevant
                      data for applying jobs{" "}
                    </span>
                  </li>
                  <li>
                    <span>Job categories</span>
                  </li>
                </ul>
              </div>
              <div className="single_box">
                <h3>Team Work</h3>
                <p>
                  We employ different methodologies according to the demands and
                  requirements of the project. Whenever our customers and
                  partners recommend using{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Agile_software_development"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Agile
                  </a> methodologies (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.arnia.com/how-we-do-scrum-in-arnia/"
                  >
                    Scrum
                  </a>{" "}
                  ,{" "}
                  <a
                    href="https://www.arnia.com/how-we-do-kanban-in-arnia/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kanban
                  </a>
                  ) from the inception of the project, we promptly apply the
                  same.
                </p>
                <p>
                  In cases where the choice is left to our engineers, we
                  carefully analyze the project specifics, and define a project
                  management methodology based on Scrum or Kanban which best
                  fits the specific project needs and context.
                </p>
                <p>
                  In this case, the choice was left to us and therefore we
                  deployed a team of one designer and one developer who
                  interacted closely with the Client Service Team.
                </p>
                <p>
                  As in all of our projects, we worked in an agile and flexible
                  way. We had daily scrum meetings with sprints and completed
                  the project in 10 days.
                </p>
                <p>
                  <b>Note:</b> Since the old website was still functional, we
                  migrated and deployed the new one within a downtime of 2
                  minutes in the night when the traffic to the website was
                  absolutely low.
                </p>
              </div>
              <div className="plateform_box">
                <div className="plateform_text">
                  <h3>Technologies</h3>
                  <h4>Website</h4>
                  <ul>
                    <li>
                      <a
                        href="https://www.php.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={php} alt="php" width={50} height={50} />
                        </span>
                        PHP
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://reactnative.dev/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={react} alt="react" width={50} height={50} />
                        </span>
                        React Native
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.mysql.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={mysql} alt="mysql" width={50} height={50} />
                        </span>
                        MySql
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="free-quote blue-quote">
              <h2 className="h2-title">
                Get a free <span>quote!</span>
              </h2>
              <div className="list-btn btn_2 btn_cut">
                <Link to="/contact">
                  <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                  </svg>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Thehumanpower;
