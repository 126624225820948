import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const Privacy = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Privacy & Conditions</h1>
          </div>
        </Container>
      </section>
      <section className="section case-section">
        <Container>
          <div className="left-padd">
            <h2>
              The following Privacy and Conditions will apply to the use of any{" "}
              <b>Website Mastser</b> services beginning on 2012, though you are
              invited to review and accept its Privacy at any time before such
              date.
            </h2>
            <div className="privacy-term">
              <p>
                Effective date: <b>2012</b>
              </p>
              <p>
                Website Masters ("we", "our", or "us") runs the{" "}
                <Link to="/home">
                  https://websitemasters.net/
                </Link>
                website (the "Service").
              </p>

              <p>
                We gather and process your data for providing and improving our
                service. Usage of our service by you is considered your consent
                to collecting and using your information in accordance with our
                policy. Until it is specified in our Privacy Policy, terms
                mentioned in this policy have similar meanings as in the Terms
                and Conditions, available at{" "}
                <Link to="/terms">
                  https://websitemasters.net/terms
                </Link>
              </p>

              <h2>Data Collection and Usage</h2>
              <p>
                We gather different sorts of data/information for diverse
                purposes to offer and refine our service offerings to you.
              </p>

              <h2>Types of Information Gathered</h2>
              <strong>Personal Information</strong>
              <p>
                While you are using our offered Service, we may demand you to
                provide us particular personal data (personally identifiable).
                We may use this data to identify or contact you. Data which is
                referred as personally identifiable may include, but certainly
                not confined to:
              </p>
              <ul className="terms-list">
                <li>First and Last name</li>
                <li>Contact Number</li>
                <li>Email Address</li>
                <li>Usage and Cookies Data</li>
              </ul>

              <strong>Usage Data</strong>
              <p>
                We may gather information that how you access and use the
                service (“Usage Data”). The Usage Data may be information,
                including computer’s IP address (i.e. Internet Protocol), our
                service pages you visit, version of browser, date and time of
                your visit, total time you spent on certain pages, exclusive
                device identifier, etc.
              </p>

              <strong id="cookies">Cookies &amp; Tracking Data</strong>
              <p>
                We make use of cookies and related tracking technologies to
                trace the activities on our service as well as hold specific
                information.
              </p>
              <p>
                Cookies are defined as files having small amount data that may
                encompass an anonymous and unique identifier. These (Cookies)
                are sent to your browser via a website and cached on your
                device. In tracking technologies, scripts, tags, and beacons are
                implemented to gather, track, analyze and improve our Service.
              </p>
              <p>
                You can direct your browser to decline the cookies or to specify
                whenever the cookies are being sent. Moreover, if you do not
                confirm the cookies, you might be unable to use the specific
                part of our Service.
              </p>

              <p>Cookies Used By Us (Some Examples):</p>
              <ul className="terms-list cookies-info">
                <li>
                  <strong>Session Cookies-</strong> We make use of Session
                  Cookies to run our Service.
                </li>
                <li>
                  <strong>Security Cookies-</strong> We use these cookies to
                  ensure security.
                </li>
              </ul>

              <h2>Data Usage</h2>
              <p>We process or use the gathered data for varied purposes:</p>
              <ul className="terms-list">
                <li>To render and maintain the Service</li>
                <li>
                  To inform you regarding changes implemented to our Service
                </li>
                <li>
                  To permit you to take part in interactive traits of our
                  Service, if you prefer doing so
                </li>
                <li>To render the best customer care and support</li>
                <li>
                  To conduct analysis of crucial information so that we can
                  refine the Service
                </li>
                <li>To evaluate and control the usage of Service</li>
                <li>To identify, prevent and resolve technical issues</li>
              </ul>

              <h2>Data Transfer</h2>
              <p>
                Your data, including Personal information, may be transmitted to
                — and managed on — computers placed outside of your residing
                province, country, state or other government jurisdictions where
                data protection policies and laws may vary from your own
                jurisdiction.
              </p>
              <p>
                If you reside outside of Indian territory and consent to share
                information with us, please mind that we transfer the
                information. Including Personal Identifiable Data to India for
                processing it there.
              </p>
              <p>
                Your agreement to our Privacy Policy pertaining to submission of
                the information from your end reflects your consent to its
                transfer.
              </p>
              <p>
                We step ahead rationally to make sure that your submitted data
                is handled securely as per this Policy. We do not transfer your
                Personal Information to any country or an organization unless
                they have proper security controls to ensure the security of
                your personal information and related data.
              </p>

              <h2>Data Disclosure</h2>

              <strong>Legal Requirements</strong>
              <p>
                We may unfold your Personal Data maintaining the belief that the
                action is crucial to:
              </p>
              <ul className="terms-list">
                <li>To adhere to a legal obligation</li>
                <li>
                  To defend and safeguard property or rights of the company
                </li>
                <li>
                  To investigate or prevent suspicious wrongdoing in relation
                  with the Service
                </li>
                <li>
                  To safeguard the personal security of the public or Service
                  users of the Service
                </li>
                <li>To protect the legal liability</li>
              </ul>

              <h2>Data Security</h2>
              <p>
                Your data security is indispensable to us, but keep in mind that
                no electronic storage, or method of internet transmission comes
                with 100% security. As we follow all commercially accepted
                methods to safeguard your Personal Information, so we cannot
                come with guarantee of complete security.
              </p>

              <h2>Service Providers</h2>
              <p>
                On our behalf, we may appoint individuals and third parties
                (“Service Providers”) to render our Service and conduct
                service-based tasks or support us in evaluating how the Service
                is implemented.
              </p>
              <p>
                The third parties can access your Personal Information merely to
                conduct these activities on our behalf and it is obligatory for
                them not to use or disclose it for any other purpose.
              </p>

              <h2>Analytics</h2>
              <p>
                We may employ third-party Service Providers for monitoring and
                analyzing the usage of our Service.
              </p>

              <strong>Google Analytics</strong>
              <p>
                Google Analytics is defined as a web analytics service, provided
                by Google, which reports and tracks website traffic. Google
                implements the data gathered to monitor and track Service usage.
                This data is further shared with other services of Google. The
                collected data may be used by Google to personalize and
                contextualize its own advertising networks’ ads.
              </p>

              <p>
                You can choose to make your activity on the available service to
                Google Analytics. You only need to install Google Analytics on
                the opt-out browser add-on. This add-on restricts Google
                Analytics JavaScript (dc.js, analytics.js, and ga.js) from
                sharing the information with the Google Analytics regarding
                visit activities.
              </p>

              <p>
                To gather more data regarding Google’s privacy practice, please
                visit Privacy and Terms page of Google{" "}
                <a target="_blank" href="https://policies.google.com/privacy?hl=e">
                  https://policies.google.com/privacy?hl=e
                </a>
              </p>

              <h2>Other websites Links</h2>

              <p>
                Our offered Service may include links of other websites which
                are not run by us. In case, you click a link of third party
                website, you would be directed to the website of that third
                party. We firmly recommend reviewing Privacy Policy of each
                website you visit.
              </p>

              <p>
                We keep no control and take no responsibility of privacy
                policies, services, content or practices of third parties.
              </p>

              <h2>Privacy of Children</h2>

              <p>
                Our Service will not entertain anyone whose age is under 18 i.e.
                Children.
              </p>

              <p>
                We do not gather personally identifiable data knowingly from
                anyone whose age is under 18. Guardian and parents of children
                who are aware of the fact that their child has shared the
                Personal Data with us should contact us. When we come to know
                that we have gathered Personal Data of children without parental
                consent and verification, we take necessary steps to eradicate
                that data from our servers immediately.
              </p>

              <h2>Alteration To The Privacy Policy</h2>
              <p>
                We may modify or update our Privacy Policy at any time. We will
                inform you regarding modifications by updating the fresh Privacy
                Policy page and through emailers as well.
              </p>
              <p>
                We will notify you through email or a specific notice of our
                service prior to the modification getting effective and we will
                share the effective data on the top of Privacy Policy page.
              </p>
              <p>
                We advise you to review this Policy periodically for the
                modifications. Alternation to this policy are considered
                effective from the time they are posted on Privacy Policy page.
              </p>

              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, the
                practices of this site, or your dealings with this website or
                our Services, please contact us at{" "}
                <b>
                  <a href="mailto:info@websitemasters.com">
                    info@websitemasters.com
                  </a>
                </b>
                , or send mail to:
              </p>
              <address>
                1st Floor, Create Campus, 11KM,
                <br />
                Arakere Bannerghatta Rd,
                <br />
                Omkar Nagar, Arekere,
                <br />
                Bengaluru, Karnataka 560076
              </address>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Privacy;
