import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { Link, useLocation} from "react-router-dom";
const Aform = () => {
  const location = useLocation();
  let values = location.state;
  values = location.state == null ? "Become Website Masters Affiliate" : location.state;
  const [show, setShow] = useState(false);
  let apiBaseUrl = window.location.hostname;
  if (apiBaseUrl === "localhost") {
    apiBaseUrl = "http://api.websitemasters.net";
  } else {
    apiBaseUrl = "http://api.websitemasters.net";
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    axios({
      method: "POST",
      url: apiBaseUrl+"/api/form/register",
      // url: apiBaseUrl+"/api/form/send_email_aform",
      // headers: {'Content-Type': 'application/json'},
      data:{
        full_name: data.full_name,
        website_url: data.website_url,
        email: data.email,
        payment_email: data.payment_email,
        promote_us: data.promote_us,
        user: "Jasvinder.wm@gmail.com",
        pass: "chuaoxqjxejybkgu",
        html:
          `<table style="border:1px solid #ccc;border-collapse: collapse; width:500px;">
        <thead><tr><th colspan="2" style="padding:8px 12px; text-align:center;border:1px solid #ccc"><b>Form Details</b></th></tr>
        </thead>
        <tbody>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Full Name:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.full_name +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Website URL:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.website_url +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Email:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.email +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">Payment Email:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.payment_email +
          `</td></tr>
        <tr><th style="padding:8px 12px; text-align:left;border:1px solid #ccc">How will you promote us:</th><td style="padding:8px 12px; text-align:left; border:1px solid #ccc">` +
          data.promote_us +
          `</td></tr>
        </tbody>
        </table>`,
        // to_email: "joevam@gmail.com,codesbyarsh@gmail.com", //
        to_email: "seshuvardhan456@gmail.com", //
        subject: values,
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log(response);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
    reset();
    setShow(true);
  };
  if (show) {
    return (
      <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Thanks for being awesome! </Alert.Heading>
        <p>
          We have received your message and would like to thank you for writing
          to us. If your inquiry is urgent, please use the telephone number
          listed below to talk to one of our staff members.
        </p>
        <p>Otherwise, we will reply by email as soon as possible.</p>
      </Alert>
    );
  }
  return (
    <Form
      className="affliate_form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Row>
        <Col lg={6}>
          <div className="form-group">
            <label htmlFor="full_name">You Full Name</label>
            <input
              id="full_name"
              autoComplete="false"
              className="form-control"
              type="text"
              {...register("full_name", {
                required: true,
                maxLength: 50,
              })}
            />
            {errors.full_name && (
              <p className="text-error">Please check the Full Name</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="payment_email">Payment Email</label>
            <input
              id="payment_email"
              autoComplete="false"
              className="form-control"
              type="email"
              {...register("payment_email", {
                required: true,
                maxLength: 50,
              })}
            />
            {errors.payment_email && (
              <p className="text-error">Please check the Email</p>
            )}
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <label htmlFor="email">Your Email</label>
            <input
              id="email"
              autoComplete="false"
              className="form-control"
              type="email"
              {...register("email", {
                required: true,
                maxLength: 50,
              })}
            />
            {errors.email && (
              <p className="text-error">Please check the Email</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="website_url">Website URL</label>
            <input
              id="website_url"
              autoComplete="false"
              className="form-control"
              type="url"
              {...register("website_url", {
                required: true,
                maxLength: 50,
              })}
            />
            {errors.website_url && (
              <p className="text-error">Please check the URL</p>
            )}
          </div>
        </Col>
        <Col md={12}>
          <div className="form-group">
            <label htmlFor="promote_us">How will you promote us</label>
            <textarea
              id="promote_us"
              autoComplete="false"
              className="form-control"
              {...register("promote_us", {
                required: true,
                maxLength: 300,
              })}
            ></textarea>
            {errors.promote_us && (
              <p className="text-error">Please check this filed</p>
            )}
          </div>
        </Col>
      </Row>
      <div className="radio-btn">
        <input type= "radio" id="terms" {...register("terms", {
                required: true,
              })} /> Agree to our 
        <Link to="/terms"><span> Terms of Use and Privacy Policy</span></Link>
        {errors.email && (
              <p className="text-error">Please check the terms and conditions</p>
            )}
      </div>
      <div className="btn-register">
        <button type="submit" className="btn btn-primary">
          Register
        </button>
      </div>
    </Form>
  );
};

export default Aform;
