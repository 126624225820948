import React from "react";
import { Container } from "react-bootstrap";
import prylin from "../assets/images/projects/prylin.webp";
import node from "../assets/images/projects/icons/Nodejs.svg";
import mongo from "../assets/images/projects/icons/Mongodb.svg";
import express from "../assets/images/projects/icons/express.svg";
import react from "../assets/images/projects/icons/react.svg";
import js from "../assets/images/projects/icons/js.svg";
import php from "../assets/images/projects/icons/php.svg";
import jquery from "../assets/images/projects/icons/jquery.svg";
import { Link } from "react-router-dom";
const Prylin = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Case Study</h1>
          </div>
        </Container>
      </section>
      <section className="section single_section">
        <Container>
          <div className="single_padd left-padd">
            <div className="single_text">
              <h2>Prylin</h2>
              <p>
                Prilyn is a clothing-marketplace for household women to sell
                their designer clothes in various categories and styles.
              </p>
              <p>
                Prilyn was conceived with a mission to empower women specially
                the home makers having a network within their circles. All
                operations were carried through whatsapp or calls for booking
                orders, tracking, payments and delivering them to clients. This
                became more challenging with growing demand causing difficulties
                in managing the inventory, missing orders, and delivery of
                goods.
              </p>
              <div className="single_box">
                <h3>Objective</h3>
                <p>
                  To build a web/mobile application that serves the needs of a
                  clothing-marketplace.{" "}
                </p>
              </div>
              <div className="single_box">
                <h3>Our Solution</h3>
                <p>
                  Webmasters built a multi interface mobile application for
                  Prilyn’s customers and vendors separately just like Flipkart
                  or Myntra which could be customized according to the type and
                  style of clothing brands on display.
                </p>
                <p>
                  For managing all operations, a web application in the form of
                  an admin panel was built for Prilyn. This digital
                  transformation helped in mitigating risk of missing order,
                  easy payments, hassle free purchasing experience for customers
                  due to the platform’s simplicity and user-friendliness.
                </p>
              </div>
              <div className="single_image">
                <img
                  src={prylin}
                  alt="single project"
                  width={1130}
                  height={619}
                  className="img-fluid"
                />
              </div>
              <div className="single_box">
                <h3>Features</h3>
                <ul>
                  <li>
                    <span>booking orders online </span>
                  </li>
                  <li>
                    <span>tracking and reporting on orders</span>
                  </li>
                  <li>
                    <span>managing expense limits</span>
                  </li>
                  <li>
                    <span>
                      monitoring/approval by the accounting departments
                    </span>
                  </li>
                  <li>
                    <span>
                      fully PCI compliant storage of all credit card information
                    </span>
                  </li>
                  <li>
                    <span>
                      manage the transactions, the balances, accept/decline
                      authorizations
                    </span>
                  </li>
                  <li>
                    <span>payments and virtual-cards</span>
                  </li>
                </ul>
              </div>
              <div className="single_box">
                <h3>Team Work</h3>
                <p>
                  We employ different methodologies according to the demands and
                  requirements of the project. Whenever our customers and
                  partners recommend using Agile methodologies (Scrum , Kanban)
                  from the inception of the project, we promptly apply the same.
                </p>
                <p>
                  In cases where the choice is left to our engineers, we
                  carefully analyze the project specifics, and define a project
                  management methodology based on Scrum or Kanban which best
                  fits the specific project needs and context.
                </p>
                <p>
                  Our development team is a combination of 6 developers based
                  out of our office: 3 backend developers,1 frontend developer,
                  2 QA. We prefer to interact closely with the Client Service
                  Team and the Infrastructure Team including project managers,
                  sales, marketing and designers for a foreseeable outcome with
                  sprints and demo releases once in 14 days.
                </p>
              </div>
              <div className="plateform_box">
                <div className="plateform_text">
                  <h3>Technologies</h3>
                  <h4>Admin Panel (Web Application)</h4>
                  <ul>
                    <li>
                      <a
                        href="https://www.php.net/ "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={php} alt="php" width={50} height={50} />
                        </span>
                        PHP
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jquery.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src={jquery}
                            alt="jquery"
                            width={50}
                            height={50}
                          />
                        </span>
                        jQuery
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://en.wikipedia.org/wiki/JavaScript"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={js} alt="js" width={48} height={48} />
                        </span>
                        javascript
                      </a>
                    </li>
                  </ul>
                  <h4>Vendor and Customer (Mobile Applications)</h4>
                  <ul>
                    <li>
                      <a
                        href="https://reactnative.dev/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src={react}
                            alt="React Native"
                            width={50}
                            height={50}
                          />
                        </span>
                        React Native
                      </a>
                    </li>
                  </ul>
                  <h4>APIs</h4>
                  <ul>
                    <li>
                      <a
                        href="https://www.mongodb.com/company"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={mongo} alt="mongo" width={50} height={50} />
                        </span>
                        MongoDB
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://expressjs.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img
                            src={express}
                            alt="express"
                            width={51}
                            height={15}
                          />
                        </span>
                        Express.js
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://nodejs.org/en/about/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          <img src={node} alt="node" width={50} height={32} />
                        </span>
                        Node.js
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="free-quote blue-quote">
              <h2 className="h2-title">
                Get a free <span>quote!</span>
              </h2>
              <div className="list-btn btn_2 btn_cut">
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Prylin;
