import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
// import blog1 from "../assets/images/blogs/blog1.png";
import Blogs from "../Helper/Blogs";
const OurBlogs = () => {
  return (
    <>
    <Helmet>
        <title>Website Masters – Our Blogs</title>
        <meta
          name="description"
          content="We design, development and marketing top-ranked websites. Contact us today to arrange a meeting and see yourself how far we can take your website."
        />
      </Helmet>
      <section className="section top-banner">
        <Container>
          <div className="banner-box">
            <h1>
              Our Blogs
            </h1>
          </div>
        </Container>
      </section>
      <section className="section blogs-section">
        <Container>
          <div className="blog-box left-padd">
            {/* <div className="row-box"> */}
              <Blogs/>
            {/* </div> */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurBlogs;