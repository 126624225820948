import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContactForm from "../Helper/ContactForm";
import Helmet from "react-helmet";
import LogoSlider from "../Helper/Logo";
import Testimonial from "../Helper/Testimonial";
import HomeSlider from "../Helper/HomeSlider";
const Home = () => {
  return (
    <>
      <Helmet>
        <title>Best web development company - Website Masters</title>
        <meta
          name="description"
          content="Website Masters is an award-winning IT Company based in India, that offers web design, software development, mobile apps and SEO services. Contact Now!"
        />
      </Helmet>
      <section className="section banner-section  blue-bg">
        <Container>
          <div className="banner-box home_banner_box">
            <div className="home_banner-text">
              <h1>
                Want to add <br/><span>More Value</span> <br />
                to your <br/>business?
              </h1>
              <h2>You have come to the right <br/>place.</h2>
            </div>
            <div className="slider_home">
              <HomeSlider/>
            </div>
          </div>
        </Container>
      </section>
      <section className="section masters-section">
        <div className="master-white"></div>
        <Container>
          <div className="master-box left-padd">
            <h2>
              We are the <span>Masters</span> of <br />
              everything you see on the web.
            </h2>
            <div className="row-box">
              <Row>
                <Col md={5}>
                  <div className="col-boxes">
                    <h3>
                      I know what I <br />
                      want.
                    </h3>
                    <div className="list-btn btn_2 rem_btn btn_cut">
                      <Link to="/pricing">
                        {/* <svg>
                          <rect
                            x="0"
                            y="0"
                            fill="none"
                            width="100%"
                            height="100%"
                          />
                        </svg> */}
                        Pricing
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="col-boxes">
                    <h3>I need help identifying the solution.</h3>
                    <div className="list-btn btn_2 rem_btn btn_cut">
                      <Link to="/contact">
                        <svg>
                          <rect
                            x="0"
                            y="0"
                            fill="none"
                            width="100%"
                            height="100%"
                          />
                        </svg>
                        Talk to our expert
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section className="section who-section">
        <Container>
          <div className="who-we left-padd">
            <h2>
              Who are <span>Website Masters?</span>
            </h2>
            <div className="who-text">
              <p>
                We are a group of proficiently trained and efficiently skilled
                veteran industry professionals who we proudly call{" "}
                <span>Masters</span>. With more than a decade worth of
                experience, our <span>Masters</span> are the best in Design and
                Development.
              </p>
              <p>
                We provide globally competent and measurably towering results by
                working closely with businesses and providing custom-tailored
                design solutions for every client.
              </p>
            </div>
          </div>
          <div className="what-wedo left-padd">
            <div className="what-do-box d-flex justify-content-between">
              <div className="list-btn btn_2 btn_cut">
                <Link to="/about">
                  <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                  </svg>
                  About Us
                </Link>
              </div>
              <div className="what-box">
                <h2>
                  What we <span>do?</span>
                </h2>
                <p>
                  We provide globally competent and measurably towering <br />
                  results by working closely with businesses and providing{" "}
                  <br />
                  custom-tailored design solutions for every client.
                </p>
              </div>
            </div>
            <div className="what-do-list listings_div ">
              <ul>
                <li>
                  <span></span>
                  Custom Website <br />
                  Development
                </li>
                <li>
                  <span></span>
                  Custom Web-App <br />
                  Development
                </li>
                <li>
                  <span></span>
                  Prototyping and User- <br />
                  Experience Design
                </li>
                <li>
                  <span></span>
                  Progress Web App <br />
                  Development
                </li>
                <li>
                  <span></span>
                  Business Consulting and <br />
                  Analysis
                </li>
                <li>
                  <span></span>
                  Third Party API <br />
                  Integration
                </li>
                <li>
                  <span></span>
                  Software Product <br />
                  Engineering
                </li>
                <li>
                  <span></span>
                  Full Stack <br />
                  Development
                </li>
                <li>
                  <span></span>
                  Digital <br />
                  Transformation
                </li>
                <li>
                  <span></span>
                  Upgradation and <br />
                  Migration
                </li>
              </ul>
            </div>
            <div className="d-flex justify-content-end mt-5 home-cnt-box">
              <div className="list-btn btn_2 cnt_fill">
                <Link to="/contact">
                  <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                  </svg>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="section video-section">
        <Container>
          <div className="our-roadmap left-padd">
            <h2>
              Our <span>Roadmap</span>
            </h2>
            <div className="roadmap-box">
              <div className="intro-box">
                <div className="road-box">
                  <h4>Introduction</h4>
                  <p>
                    It starts with a friendly <br />
                    introduction.
                  </p>
                </div>
              </div>
              <div className="intro-box">
                <div className="road-box">
                  <h4>Discovery</h4>
                  <p>
                    We get to know your business, its processes, <br />
                    and the underlying causes for your needs.
                  </p>
                </div>
              </div>
              <div className="intro-box">
                <div className="road-box">
                  <h4>Execution</h4>
                  <p>
                    Now it’s time to build a roadmap for your <br />
                    project’s success.
                  </p>
                  <span className="popover">
                    Website Masters keep you informed every step of the way,
                    through planning and testing, ensuring your project is ready
                    for its <b>GO LIVE day</b>.
                  </span>
                </div>
              </div>
              <div className="intro-box">
                <div className="road-box">
                  <h4>Training</h4>
                  <p>
                    We provide training and support for your new solution to{" "}
                    <br />
                    ensure you and your people can get the most out of it.
                  </p>
                </div>
              </div>
            </div>
            <div className="you-get">
              <h2 className="h2-title">
                Our <span>Promise</span>
              </h2>
              <div className="what-do-list list-blue listings_div new_list_divs">
                <ul>
                  <li>
                    <span></span>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 7.5V15L20 17.5"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    On Time Delivery
                  </li>
                  <li>
                    <span></span>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 26.25V23.75C20 22.4239 19.4732 21.1521 18.5355 20.2145C17.5979 19.2768 16.3261 18.75 15 18.75H6.25C4.92392 18.75 3.65215 19.2768 2.71447 20.2145C1.77678 21.1521 1.25 22.4239 1.25 23.75V26.25"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.625 13.75C13.3864 13.75 15.625 11.5114 15.625 8.75C15.625 5.98858 13.3864 3.75 10.625 3.75C7.86358 3.75 5.625 5.98858 5.625 8.75C5.625 11.5114 7.86358 13.75 10.625 13.75Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21.25 13.75L23.75 16.25L28.75 11.25"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Expert’s Help
                  </li>
                  <li>
                    <span></span>
                    <svg
                      width="22"
                      height="28"
                      viewBox="0 0 22 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 26.848C11 26.848 21 21.6784 21 13.924V4.87719L11 1L1 4.87719V13.924C1 21.6784 11 26.848 11 26.848Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    NDA Confidential
                  </li>
                  <li>
                    <span></span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.25 15.75C23.25 16.413 22.9866 17.0489 22.5178 17.5178C22.0489 17.9866 21.413 18.25 20.75 18.25H5.75L0.75 23.25V3.25C0.75 2.58696 1.01339 1.95107 1.48223 1.48223C1.95107 1.01339 2.58696 0.75 3.25 0.75H20.75C21.413 0.75 22.0489 1.01339 22.5178 1.48223C22.9866 1.95107 23.25 2.58696 23.25 3.25V15.75Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Maintenance Support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="testimonial_section">
        <Container>
          <div className="client-box left-padd">
            <h2 className="h2-title text-black">
              Listen to what our <br />
              <span>customers have to say.</span>
            </h2>
            <Testimonial />
          </div>
        </Container>
      </section>
      <section className="section our-clients">
        <Container>
          <div className="client-box left-padd">
            <h2 className="h2-title text-black">
              Our <span>Clients</span>
            </h2>
            <LogoSlider />
          </div>
        </Container>
      </section>
      <section className="section lets-talk">
        <Container>
          <div className="left-padd">
            <div className="lets_talk_box">
              <Row>
                <Col lg={6}>
                  <h2 className="h2-title big-h2-title">
                    Let’s <span>talk</span>
                  </h2>
                  <div className="fill-form-text">
                    Fill out the form and we’ll get back to you as soon as we
                    can.
                  </div>
                </Col>
                <Col lg={6}>
                  <ContactForm />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Home;
