import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import vortex from "../assets/images/projects/vortex_bg.webp";

import php from "../assets/images/projects/icons/php.svg";
import mysql from "../assets/images/projects/icons/mysql.svg";
import jquery from "../assets/images/projects/icons/jquery.svg";


const Vortex = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Case Study</h1>
          </div>
        </Container>
      </section>
      <section className="section single_section">
        <Container>
          <div className="single_padd left-padd">
            <div className="single_text">
              <h2>Vortex Solution</h2>
              <p>
                Vortex provides job and business opportunities for household
                ladies, students, working professionals on a full time or
                part-time basis. Their aim is to provide as much employment as
                possible for aspiring individuals.
              </p>
              <p>
                With the advent of e-commerce platforms such as Flipkart and
                Amazon running discount sales frequently, local vendors with
                zero exposure to online selling incurred a huge loss of
                business. Identifying this problem, Vortex began its operations
                by creating a virtual group through <b>WhatsApp</b> and other
                such means to develop and nurture the local businesses from
                different categories. As this gained popularity, demand and
                supply grew enormously causing problems in its structural
                operations.
              </p>
              <div className="single_box">
                <h3>Objective</h3>
                <p>
                  Creating a marketplace that provides space for vendors with
                  different products to showcase, sell, and scale their growth
                  efficiently.
                </p>
              </div>
              <div className="single_box">
                <h3>Our Solution</h3>
                <p>
                  Website masters came up with the ideal solution that solved
                  all the difficulties faced on the operational front by
                  providing an automated system consisting of web and mobile
                  applications.
                </p>
                <p>
                  This solution was curated based on the research conducted
                  across different vendor needs and customer requirements. The
                  UI/UX design for the mobile application was tailor made to
                  meet the expectations on both ends of the spectrum while
                  Vortex was given a web app to manage its vendors, customers,
                  orders, payments, and other peripherals.
                </p>
              </div>
              <div className="single_image">
                <img src={vortex} alt="single project" width={1000} height={1070} className="img-fluid"/>
              </div>
              <div className="single_box">
                <h3>Features</h3>
                <ul>
                  <li>
                    <span>booking orders online </span>
                  </li>
                  <li>
                    <span>tracking and reporting on orders</span>
                  </li>
                  <li>
                    <span>managing expense limits</span>
                  </li>
                  <li>
                    <span>
                      monitoring/approval by the accounting departments
                    </span>
                  </li>
                  <li>
                    <span>
                      fully PCI compliant storage of all credit card information
                    </span>
                  </li>
                  <li>
                    <span>
                      manage the transactions, the balances, accept/decline
                      authorizations
                    </span>
                  </li>
                  <li>
                    <span>payments and virtual-cards</span>
                  </li>
                </ul>
              </div>
              <div className="single_box">
                <h3>Team Work</h3>
                <p>
                  We employ different methodologies according to the demands and
                  requirements of the project. Whenever our customers and
                  partners recommend using Agile methodologies (Scrum , Kanban)
                  from the inception of the project, we promptly apply the same.
                </p>
                <p>
                  In cases where the choice is left to our engineers, we
                  carefully analyze the project specifics, and define a project
                  management methodology based on Scrum or Kanban which best
                  fits the specific project needs and context.
                </p>
                <p>
                  Our development team is a combination of 6 developers based
                  out of our office: 3 backend developers,1 frontend developer,
                  2 QA. We prefer to interact closely with the Client Service
                  Team and the Infrastructure Team including project managers,
                  sales, marketing and designers for a foreseeable outcome with
                  sprints and demo releases once in 14 days.
                </p>
              </div>
              <div className="plateform_box">
                <div className="plateform_text">
                  <h3>Technologies</h3>
                  <h4>Admin Panel (Web Application)</h4>
                  <ul>
                    <li>
                      <a href="https://www.php.net/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={php} alt="php" width={50} height={50}/>
                        </span>
                        PHP
                      </a>
                    </li>
                    <li>
                      <a href="https://www.mysql.com/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={mysql} alt="my sql" width={50} height={50}/>
                        </span>
                        My Sql
                      </a>
                    </li>
                  </ul>
                  <h4>Web Software</h4>
                  <ul>
                    <li>
                      <a href="https://www.php.net/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={php} alt="php" width={50} height={50}/>
                        </span>
                        PHP
                      </a>
                    </li>
                    <li>
                      <a href="https://www.mysql.com/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={mysql} alt="my sql" width={50} height={50}/>
                        </span>
                        My Sql
                      </a>
                    </li>
                    <li>
                      <a href="https://jquery.com/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={jquery} alt="jquery" width={50} height={50}/>
                        </span>
                        jQuery
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="free-quote blue-quote">
              <h2 className="h2-title">
                Get a free <span>quote!</span>
              </h2>
              <div className="list-btn btn_2 btn_cut">
                <a href="/contact">
                  <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                  </svg>
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Vortex;
