import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "../Helper/ContactForm";
import Helmet from "react-helmet";
const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Website Masters – Get in touch us</title>
        <meta
          name="description"
          content="We design, development and marketing top-ranked websites. Contact us today to arrange a meeting and see yourself how far we can take your website."
        />
      </Helmet>
      <section className="section top-banner">
        <Container>
          <div className="banner-box">
            <h1>
              Have a <span>project?</span>
            </h1>
          </div>
        </Container>
      </section>
      <section className="section contact-page">
        <Container>
          <div className="left-padd">
            <div className="lets_talk_box">
              <Row>
                <Col lg={6}>
                  <h2 className="h2-title big-h2-title">
                    Let’s <span>talk</span>
                  </h2>
                  <div className="fill-form-text">
                    Fill out the form and we’ll get back to you as soon as we
                    can.
                  </div>
                </Col>
                <Col lg={6}>
                  <ContactForm />
                </Col>
              </Row>
              <Row className="reach-us align-items-end">
                <Col lg={7}>
                  <div className="contact_infos">
                    <h3>
                      Or reach us <span>at</span>
                    </h3>
                    <p>
                      <i className="fa fa-phone" aria-hidden="true">
                      <img src="./phone.svg" alt="phone" width={24} height={24}/>
                      </i>
                      <a href="tel:91 63861 63507">+91 63861 63507</a>
                    </p>
                    <p>
                      <i className="fa fa-envelope-o" aria-hidden="true">
                      <img src="./envelope.svg" alt="envelope" width={24} height={24}/>
                      </i>
                      <a href="mailto:info@websitemasters.com">
                        info@websitemasters.com
                      </a>
                    </p>
                    <p>
                      <i className="fa fa-map-marker" aria-hidden="true">
                        <img src="./map-pin.svg" alt="map location" width={24} height={24}/>
                      </i>
                      <span>
                        1st Floor, Create Campus, 11KM, Arakere Bannerghatta Rd,
                        Omkar Nagar, Arekere, Bengaluru, Karnataka 560076
                      </span>
                    </p>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="map-box">
                    <iframe width={350} height={200} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.331671080919!2d77.59265221460257!3d12.886381790911793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15290958f971%3A0x5297cb07993c48bb!2sEuodias%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1665656390723!5m2!1sen!2sin"></iframe>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Contact;
