import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
const Singleblog = () => {
  const parms = useParams();
  const [user] = useState([]);
  const [blog, setData] = useState([]);

  // fetch maid names from api
  // const [blog, setMaid] = useState([]);
  // const [mds, setMd] = useState([]);
  
 
  useEffect(() => {
    
    let apiBaseUrl = window.location.hostname;
    if (apiBaseUrl === "localhost") {
      // apiBaseUrl = "http://localhost:3105/api";
      apiBaseUrl = "http://api.websitemasters.net";
    } else {
      apiBaseUrl = "http://api.websitemasters.net";
    }
    const url = apiBaseUrl + "/api/blogs/get_blog_by_id";
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: parms.id,
        // f_new_status: "1",
      }),
    };
    async function fetchData() {
      await fetch(url, settings)
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          console.log(res);
          const blog = res.data;
          setData(blog);
        })
    }
    fetchData();
  }, []);
  // console.log(blog);

  return (
    <>
      <Row>
        
      {user && ( 
        <Col md={12}>
          <div className="blogdetail-boxes">
            <div class="single_blog_image">
            <img src={'http://api.websitemasters.net'+ blog.image_url} alt="blog" height={450} />
            </div>
            <div className="blog-details">
              <h4>{blog.blog_date}</h4>
              <h3> {blog.blog_title}</h3>
              <p>
                {blog.blog_description}
              </p>

              {/* <Row>
                <Col md={4}>
                  <div className="blog-image">
                    <img src={'http://api.websitemasters.net'+ blog.image_url} alt="blog" />
                  </div>
                </Col>
                <Col md={8}>
                  <div className="content">
                    <p>
                    {blog.blog_description}
                    </p>
                    <p>
                    {blog.blog_description}
                    </p>
                  </div>
                </Col>
              </Row> */}
              {/* <p>
              {blog.blog_description}
              </p> */}
            </div>
          </div>
        </Col>
       )}
      </Row>
    </>
  );
};

export default Singleblog;
