import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import actionplus from "../assets/images/projects/actionplus.webp";
import node from "../assets/images/projects/icons/Nodejs.svg";
import mongo from "../assets/images/projects/icons/Mongodb.svg";
import express from "../assets/images/projects/icons/express.svg";
import react from "../assets/images/projects/icons/react.svg";
import responsive from "../assets/images/projects/icons/responsive.svg";
import wordpress from "../assets/images/projects/icons/wordpress.svg";
import php from "../assets/images/projects/icons/php.svg";
import mysql from "../assets/images/projects/icons/mysql.svg";
import jquery from "../assets/images/projects/icons/jquery.svg";
import { Link } from "react-router-dom";
const ActionPlus = () => {
  return (
    <>
      <section className="section top-banner">
        <Container>
          <div className="banner-box left-padd">
            <h1 className="mb-3">Case Study</h1>
          </div>
        </Container>
      </section>
      <section className="section single_section">
        <Container>
          <div className="single_padd left-padd">
            <div className="single_text">
              <h2>ActionPlus</h2>
              <p>
                Action Plus offers a variety of straight-to-home services such
                as home cleaning and maids, home appliances repair, personal
                grooming, and other tenant management services within
                residential gated communities.
              </p>
              <p>
                Before transforming their operations on to an automated
                software, the recipient of our services managed all aspects of
                scheduling daily tasks, micro-managing servicemen, payments, and
                sales manually by documenting every report, business expenses
                with the help of documents and excel sheets which consumed more
                time and effort, lacking efficiency.
              </p>
              <div className="single_box">
                <h3>Objective</h3>
                <p>
                  To create a web/mobile application that provides all services
                  to customers just by selecting their preferences with ease.
                </p>
              </div>
              <div className="single_box">
                <h3>Our Solution</h3>
                <p>
                  Webmasters built a multi interface mobile application for
                  Action Plus customers, just like Uber company with a
                  meticulously well thought UI-design and user experience. For
                  managing all operations, a web application in the form of an
                  admin panel was built for Action Plus.
                </p>
                <p>
                  This digital transformation helped in minimizing their
                  workload from individually managing tasks to automated
                  systems, easy payments, hassle free booking experience for
                  customers due to the platform’s simplicity and
                  user-friendliness.
                </p>
              </div>
              <div className="single_image">
                <img src={actionplus} alt="single project" width={1112} height={611} className="img-fluid"/>
              </div>
              <div className="single_box">
                <h3>Features</h3>
                <ul>
                  <li>
                    <span>booking orders online </span>
                  </li>
                  <li>
                    <span>tracking and reporting on orders</span>
                  </li>
                  <li>
                    <span>managing expense limits</span>
                  </li>
                  <li>
                    <span>
                      monitoring/approval by the accounting departments
                    </span>
                  </li>
                  <li>
                    <span>
                      fully PCI compliant storage of all credit card information
                    </span>
                  </li>
                  <li>
                    <span>
                      manage the transactions, the balances, accept/decline
                      authorizations
                    </span>
                  </li>
                  <li>
                    <span>payments and virtual-cards</span>
                  </li>
                </ul>
              </div>
              <div className="single_box">
                <h3>Team Work</h3>
                <p>
                  We employ different methodologies according to the demands and
                  requirements of the project. Whenever our customers and
                  partners recommend using Agile methodologies (Scrum , Kanban)
                  from the inception of the project, we promptly apply the same.
                </p>
                <p>
                  In cases where the choice is left to our engineers, we
                  carefully analyze the project specifics, and define a project
                  management methodology based on Scrum or Kanban which best
                  fits the specific project needs and context.
                </p>
                <p>
                  Our development team is a combination of 6 developers based
                  out of our office: 3 backend developers,1 frontend developer,
                  2 QA. We prefer to interact closely with the Client Service
                  Team and the Infrastructure Team including project managers,
                  sales, marketing and designers for a foreseeable outcome with
                  sprints and demo releases once in 14 days.
                </p>
              </div>
              <div className="plateform_box">
                <div className="plateform_text">
                  <h3>Technologies</h3>
                  <h4>Website (Informative)</h4>
                  <ul>
                    <li>
                      <a
                        href="https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Responsive_Design"
                        target="_blank" rel="noopener noreferrer"
                      >
                        <span>
                          <img src={responsive} alt="Responsive" width={50} height={50}/>
                        </span>
                        Responsive
                      </a>
                    </li>
                    <li>
                      <a href="https://wordpress.org/about/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={wordpress} alt="wordpress" width={41} height={41}/>
                        </span>
                        Wordpress
                      </a>
                    </li>
                    <li>
                      <a href="https://www.php.net/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={php} alt="php" width={50} height={50}/>
                        </span>
                        PHP
                      </a>
                    </li>
                    <li>
                      <a href="https://jquery.com/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={jquery} alt="jquery" width={50} height={50}/>
                        </span>
                        jQuery
                      </a>
                    </li>
                    <li>
                      <a href="https://www.mysql.com/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={mysql} alt="mysql" width={50} height={50}/>
                        </span>
                        MySql
                      </a>
                    </li>
                  </ul>
                  <h4>Admin Panel (Web Application)</h4>
                  <ul>
                    <li>
                      <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={react} alt="React js" width={50} height={50}/>
                        </span>
                        React Js
                      </a>
                    </li>
                  </ul>
                  <h4>Vendor and Customer (Mobile Applications)</h4>
                  <ul>
                    <li>
                      <a href="https://reactnative.dev/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={react} alt="React Native" width={50} height={50}/>
                        </span>
                        React Native
                      </a>
                    </li>
                  </ul>
                  <h4>APIs</h4>
                  <ul>
                    <li>
                      <a href="https://www.mongodb.com/company" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={mongo} alt="mongo" width={50} height={50}/>
                        </span>
                        MongoDB
                      </a>
                    </li>
                    <li>
                      <a href="https://expressjs.com/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={express} alt="express" width={51} height={15}/>
                        </span>
                        Express.js
                      </a>
                    </li>

                    <li>
                      <a href="https://nodejs.org/en/about/" target="_blank" rel="noopener noreferrer">
                        <span>
                          <img src={node} alt="node" width={50} height={32}/>
                        </span>
                        Node.js
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="free-quote blue-quote">
              <h2 className="h2-title">
                Get a free <span>quote!</span>
              </h2>
              <div className="list-btn btn_2 btn_cut">
                <Link to="/contact">
                  <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                  </svg>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ActionPlus;
